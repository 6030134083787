import React from "react";
import { Fetch } from "./fetch";
import { useDispatch, useSelector } from "react-redux";
import { badgesAction } from "../redux/actions";

async function BadgeUpdate(idEmpresa, dispatch) {
  const { data: homeSlideCount } = await Fetch(
    `home-slides/count/porEstado?idEmpresa=${idEmpresa}`,
    "get"
  );
  const { data: paginaPrincipalCount } = await Fetch(
    `pagina-principals/count/porEstado?idEmpresa=${idEmpresa}`,
    "get"
  );
  Promise.all([homeSlideCount, paginaPrincipalCount]).then((response) => {
    dispatch(
      badgesAction({
        homeSlide: response[0],
        paginaPrincipal: response[1],
      })
    );
  });
}

export default BadgeUpdate;
