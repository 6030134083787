import React from 'react';
import { Link } from "react-router-dom";
import { Dropdown, Image } from 'react-bootstrap';
import './style.scss';
import { connect } from 'react-redux';
import { clearStore } from '../../utils/storage';
import { logoutAction } from '../../redux/actions';
const TopbarUserProfile = (props) => {
    const { user, logoutActionStore } = props;

    const handleLogout = () => {
        logoutActionStore();
        clearStore();
        window.location.reload();
    }

    return (
        <React.Fragment>
            <Dropdown as='li' className="avtar-dropdown">
                <Dropdown.Toggle>
                    <i class="fas fa-user"></i>
                    {user && user.NombreCompleto}
                </Dropdown.Toggle>
                <Dropdown.Menu as="ul" className={`top-dropdown`}>
                    {/* <Dropdown.Item as='li'>
                        <Link to="#be">
                            <i className="icon-user" /> 
                            Perfil
                        </Link>
                    </Dropdown.Item>
                    <Dropdown.Item as='li'>
                        <Link to="#be">
                            <i className="icon-settings" /> 
                            Configuracion
                        </Link>
                    </Dropdown.Item> */}
                    <Dropdown.Item as='li'>
                        <a target={"_blank"} href="https://xd.adobe.com/view/17a0f051-5d4c-430f-a632-083a1af61562-42f3/?fullscreen&hints=off">
                            <i className="fas fa-file-download" />
                            Manual
                        </a>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item as='li'>
                        <Link to="#" onClick={() => handleLogout()}>
                            <i className="icon-logout" />
                            Salir
                        </Link>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {

    return {
        user: state.user.data,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logoutActionStore: () => { dispatch(logoutAction()) }
    }

};

export default connect(mapStateToProps, mapDispatchToProps)(TopbarUserProfile);