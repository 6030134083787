import { useEffect, useState } from "react";

import MenuAdminHB from "../data/menu-hb/menu-admin.json";
import MenuSuperAdminHB from "../data/menu-hb/menu-super-admin.json";
import MenuCreadorHB from "../data/menu-hb/menu-creador.json";
import MenuAprobadorHB from "../data/menu-hb/menu-aprobador.json";
import MenuTeleperformanceHB from "../data/menu-hb/menu-teleperformance.json";

import MenuCreadorPS from "../data/menu-ps/menu-creador.json";
import MenuAprobadorPS from "../data/menu-ps/menu-aprobador.json";
import MenuTeleperformancePS from "../data/menu-ps/menu-teleperformance.json";
import MenuAdminPS from "../data/menu-ps/menu-admin.json";
import MenuSuperAdminPS from "../data/menu-ps/menu-super-admin.json";

const useMenu = (user) => {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (!user) return;

    console.log("USER ROLE",user.role.type)

    if (user.empresa.nombre == "PS") {
      switch (user.role.type) {
        case "creador":
          setMenu(MenuCreadorPS.filter((x) => x.isRender  || x.isRender==null));
          break;
        case "aprobador":          
          setMenu(MenuAprobadorPS.filter((x) => x.isRender || x.isRender==null));
          break;
        case "teleperformance":
          setMenu(MenuTeleperformancePS.filter((x) => x.isRender  || x.isRender==null));
          break;
        case "admin":
          setMenu(MenuAdminPS.filter((x) => x.isRender  || x.isRender==null));
          break;
        case "superadmin":          
          setMenu(MenuSuperAdminPS.filter((x) => x.isRender  || x.isRender==null));
          break;
      }
    } 
  }, [user]);

  return {
    menu,
  };
};

export default useMenu;
