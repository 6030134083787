/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button, Modal } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AlertInfo } from "../../../../../components/alert";
import DataGridForm from "../../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../../redux/actions";
import { catchError, Fetch, FetchApi, fetchLogs } from "../../../../../utils/fetch";
import { formatDate } from "../../../../../utils/formatDate";
import "./lista.scss";

function ListaPacientes({ isLoadingAction, isLoading }) {

  
  const user = useSelector((state) => state.user.data);

  const [contenido, setContenido] = React.useState(null);
  const [estados, setEstados] = React.useState(null);
  const [memberSelected,setMemberSelected]=React.useState({});
  const [state, setState] = React.useState({
    fecha: "",
    estado: "",
    tipoDocumento: "",
    numeroDocumento: "",
    accion: "",
    observations: "",
    idFamilygroup: 0,
    visibleModal: false,
  });

  React.useEffect(() => {
    onDatosInciales();
  }, []);

  React.useEffect(() => {
    setContenido([]);
  }, [state.fecha, state.estado, state.tipoDocumento, state.numeroDocumento]);

  const onDatosInciales = async () => {
    try {
      isLoadingAction(true);
      const catalogos = FetchApi(
        `customers/Api/v1/User/GetStatusRequestFamilyGroups`,
        "get",
        ""
      );
      const solicitudes = FetchApi(
        `customers/Api/v1/User/GetAllRequestFamilyGroups`,
        "post",
        {
          fechaSolicitud: "",
          estado: "",
          tipoDocumento: "",
          numeroDocumento: "",
        }
      );

      Promise.all([catalogos, solicitudes]).then((response) => {
        const help = response[0].data;
        const helpSetting = response[1].data[0];

        setEstados(response[0].data?.data?.Catalogues);
        setContenido(response[1].data?.data?.Solicitudes);
        isLoadingAction(false);
      });
    } catch (error) {
      catchError(error);
      isLoadingAction(false);
    }
  };

  const getContenido = async () => {
    isLoadingAction(true);

    try {
      isLoadingAction(true);
      const { data } = await FetchApi(
        `customers/Api/v1/User/GetAllRequestFamilyGroups`,
        "post",
        {
          fechaSolicitud: state.fecha.split("-").reverse().join("-"),
          estado: state.estado,
          tipoDocumento: state.tipoDocumento,
          numeroDocumento: state.numeroDocumento,
        }
      );
      setContenido(data?.data?.Solicitudes);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  const getArchivo = async (idMember,nombreCompleto) => {
    try {
      isLoadingAction(true);
      const { data } = await FetchApi(
        `customers/Api/v1/User/GetZipFileByMemberFamily?idMember=${idMember}`,
        "post",
        ""
      );
      isLoadingAction(false);

      if (data?.responseCode === 200) {
        var a = document.createElement("a");
        a.href = "data:application/zip;base64," + data?.data?.Zip;
        a.download = `PeticionGrupoFamiliar_${nombreCompleto.replaceAll(" ","")}.zip`;
        a.click();
      } else {
        AlertInfo(data?.message);
      }
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onCargarModal = async (data,idFamilygroup, accion) => {
    setMemberSelected(data)
    setState({
      ...state,
      idFamilygroup,
      accion,
      observations: "",
      visibleModal: true,
    });
  };

  const onAprobarReprobar = async () => {
    
    //accion R === Rechazar
    if (state.accion === "R" && state.observations.trim().length <= 0) {
      AlertInfo("Debe de ingresar la observación");
      return false;
    }

    isLoadingAction(true);

    try {
      isLoadingAction(true);
      if (state.accion === "A") {
        //Aprobar
        const { data } = await FetchApi(
          `customers/Api/v1/FamiliarGroup/AcceptFamilyGroup`,
          "post",
          {
            observations: state.observations,
            familygroupId: state.idFamilygroup,
          }
        );
        setState({
          ...state,
          visibleModal: false,
          fecha: "",
          estado: "",
          tipoDocumento: "",
          numeroDocumento: "",
        });
        setContenido(data?.data?.FamilyGroupRequests);

        fetchLogs({
          idLogType: 2,
          idType: 3,
          description:`El administrador ${user.nombre} (${user.email}) ha cambiado el estado del miembro familiar ${memberSelected.nombreCompleto} a aprobado`,
        });

        AlertInfo(data?.message);
      } else {
        //Rechazar
        const { data } = await FetchApi(
          `customers/Api/v1/FamiliarGroup/RejectFamilyGroup`,
          "post",
          {
            observations: state.observations,
            familygroupId: state.idFamilygroup,
          }
        );
        setState({
          ...state,
          visibleModal: false,
          fecha: "",
          estado: "",
          tipoDocumento: "",
          numeroDocumento: "",
        });
        setContenido(data?.data?.FamilyGroupRequests);
        fetchLogs({
          idLogType: 2,
          idType: 3,
          description:`El administrador ${user.nombre} (${user.email}) ha cambiado el estado del miembro familiar ${memberSelected.nombreCompleto} a reprobado`,
        });
        AlertInfo(data?.message);
      }
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function DescargaArchivo(data) {
    console.log("DATA",data)
    return (
      <>
        <span role={"button"} onClick={() => getArchivo(data.idMiembro,data.nombreCompleto)}>
          <i className="fa fa-download"></i>
        </span>
      </>
    );
  }

  function Accion(data) {
    return (
      data.idEstado === "PDA" && (
        <>
          <span
            role={"button"}
            title="Aprobar"
            onClick={() => onCargarModal(data,data.idMiembro, "A")}
          >
            <i className="fa fa-check fa-2x"></i>
          </span>{" "}
          <span
            role={"button"}
            title="Reprobar"
            onClick={() => onCargarModal(data.idMiembro, "R")}
          >
            <i className="fa fa-times fa-2x"></i>
          </span>
        </>
      )
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>SOLICITUDES DE ADHESIÓN A GRUPO FAMILIAR</Card.Header>
            <Card.Body>
              <Row className="mb-15">
                <Col md="2">
                  <label>Fecha de solicitud</label>
                  <input
                    placeholder="DD-MM-YYYY"
                    name="fecha"
                    onChange={onChange}
                    value={state.fecha}
                    type="date"
                    class="form-control"
                  />
                </Col>
                <Col md="2">
                  <label>Estado</label>
                  <select
                    class="form-control"
                    name="estado"
                    onChange={onChange}
                    value={state.estado}
                  >
                    <option selected value="">
                      Seleccione uno
                    </option>
                    {estados?.map((item, idx) => (
                      <option value={item.codigo}>{item.descripcion}</option>
                    ))}
                  </select>
                </Col>
                <Col md="2">
                  <label>Tipo doc.</label>
                  <select
                    class="form-control"
                    name="tipoDocumento"
                    onChange={onChange}
                    value={state.tipoDocumento}
                  >
                    <option selected="selected" value="">
                      Seleccione uno
                    </option>
                    <option value="0">DU</option>
                    <option value="2">Pasaporte</option>
                  </select>
                </Col>
                <Col md="2">
                  <label>Nro. documento</label>
                  <input
                    type="number"
                    name="numeroDocumento"
                    onChange={onChange}
                    value={state.numeroDocumento}
                    class="form-control"
                  />
                </Col>
                <Col md="2" className="mt-31">
                  <label>&nbsp;</label>
                  <Button onClick={getContenido}>
                    <i className="fa fa-search mr-2"></i>Buscar
                  </Button>
                </Col>
              </Row>

              <div className="table-responsive">
                <DataGridForm
                  dataSource={contenido}
                  keyExpr="idMiembro"
                  columns={[
                    { caption: "Nombre completo", dataField: "nombreCompleto" },
                    { caption: "Tipo doc.", dataField: "tipoDocumento" },
                    { caption: "Nro. doc.", dataField: "numeroDocumento" },
                    { caption: "Credencial", dataField: "credencial" },
                    { caption: "Fecha de nac.", dataField: "fechaNacimiento" },
                    { caption: "Parentesco", dataField: "parentesco" },
                    {
                      caption: "Fecha de solicitud",
                      dataField: "fechaSolicitud",
                    },
                    {
                      caption: "Doc. adjunta",
                      alignment: "center",
                      render: "DescargaArchivo",
                      cellRender: { DescargaArchivo },
                    },
                    { caption: "Estado", dataField: "estado" },
                    { caption: "Observaciones", dataField: "observacion" },
                    { caption: "Responsable", dataField: "responsable" },
                    {
                      caption: "Acción",
                      alignment: "center",
                      render: "Accion",
                      cellRender: { Accion },
                      hidingPriority: 0,
                    },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        show={state.visibleModal}
        size="lg"
        onHide={() => setState({ ...state, visibleModal: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {state.accion === "A" ? "Aprobar solicitud" : "Reprobar solicitud"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            name="observations"
            className="form-control"
            onChange={onChange}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onAprobarReprobar}>
            {state.accion === "A" ? "Aprobar" : "Reprobar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPacientes);
