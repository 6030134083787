/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DataGridForm from "../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../redux/actions";
import { catchError, Fetch, FetchApi } from "../../../../utils/fetch";
import { formatDate } from "../../../../utils/formatDate";
import "./facturacion.scss";
import PieChart, {
  Series,
  Label,
  Connector,
  Size,
  Export,
} from "devextreme-react/pie-chart";

function Facturacion({ isLoadingAction, isLoading }) {
  const [contenido, setContenido] = React.useState(null);

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await FetchApi(
        `patient/Api/v1/Costumers/GetStatisticsInvoice`,
        "get",
        null
      );
      //******** Se agrega el for para editar la fecha, quitar la "T" antes de la hora */
      for (let i = 0; i < data.data.statistics.length; i++) {
        const fecha = data.data.statistics[i].fecha;
        const nuevaFecha = fecha.replace("T", " ");
        data.data.statistics[i].fecha = nuevaFecha;
      }
      setContenido(data?.data);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  const descargar=()=>{

    FetchApi(
      `patient/Api/v1/Costumers/GetStatisticsInvoiceExcel`,
      "get",
      null
    ).then((response) => {
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.data.file}`;
      const downloadLink = document.createElement("a");
      const fileName = "statistics.xlsx";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });

   
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header></Card.Header>
            <Card.Body>
              {/* <Row className='mb-15'>
                                <Col md="2">
                                    <label>Fecha desde</label>
                                    <input type="date" class="form-control" />                                    
                                </Col>
                                <Col md="2">
                                    <label>Fecha hasta</label>
                                    <input type="date" class="form-control" />                                    
                                </Col>
                                <Col md="2" className='mt-31'>
                                <Button><i className="fa fa-search mr-2"></i>Filtrar</Button>
                                </Col>
                            </Row> */}

              <PieChart
                id="pie"
                dataSource={contenido?.pie}
                palette="Bright"
                title="BAJADA DE FACTURAS"
              >
                <Series argumentField="nombre" valueField="porcentaje">
                  <Label visible={true}>
                    <Connector visible={true} width={1} />
                  </Label>
                </Series>
                <Export enabled={true} />
              </PieChart>

              <div className="table-responsive">
                <DataGridForm
                  dataSource={contenido?.statistics}
                  keyExpr="indice"
                  columns={[
                    { caption: "Fecha y Hora", dataField: "fecha" },
                    { caption: "Asociado", dataField: "asociado" },
                    { caption: "Tipo", dataField: "tipo" },
                    { caption: "Descripción", dataField: "descripcion" },
                  ]}
                />                
                <button type="button" className="btn btn-success mt-1" onClick={descargar}><i className="fa fa-file-excel-o" /> Exportar excel</button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Facturacion);
