/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataGridForm from "../../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../../redux/actions";
import { catchError, Fetch, FetchApi } from "../../../../../utils/fetch";
import "./empresas.scss";
import "devextreme/dist/css/dx.light.css";
import { LoadPanel } from "devextreme-react/load-panel";

function Empresas() {
  const [state, setState] = React.useState({
    filter: "",
    random: Math.random(),
  });
  const [contenido, setContenido] = React.useState(null);
  const [fullContenido, setFullContenido] = React.useState(null);
  const [showLoading, setShowLoading] = React.useState(false);
  let history = useHistory();

  const showLoadingPanel = () => {
    setShowLoading(true);
  };

  const getContenido = async () => {
    showLoadingPanel();
    const data = await FetchApi(
      `customers/Api/v1/User/GetCompanyFilter`,
      "post",
      {
        pageIndex: 1,
        pageSize: 100000,
        filter: state.filter,
        random: state.random,
      }
    );
    setContenido(data.data.data.CompanyGroup);
    setFullContenido(data.data.data.CompanyGroup);
    setShowLoading(false);
  };

  const filteredData = (filter) => {
    if (filter) {
      let filtered = fullContenido.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(filter.toLowerCase())
        )
      );
      setContenido(filtered);
    } else {
      setContenido(fullContenido);
    }
  };

  React.useEffect(() => {
    getContenido();
  }, [state.random]);

  const onChange = async (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onBuscar = () => {
    filteredData(state.filter);
    // setState({ ...state, random: Math.random() });
  };

  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      onBuscar();
    }
  }

  function showLoadingFalse() {
    setShowLoading(false);
  }

  const editarEmpresa = (e) => {
    const data = e.row.data;
    history.push("/modificar-empresa/" + data.idCustomer);
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>Empresas registradas</Card.Header>
            <Card.Body>
              <Row className="mb-15">
                <Col md="3">
                  <input
                    placeholder="Buscar empresa"
                    name="filter"
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    value={state.filter}
                    type="text"
                    class="form-control"
                  />
                </Col>
                <Col md="2">
                  <Button onClick={onBuscar}>
                    <i className="fa fa-search mr-2"></i>Filtrar
                  </Button>
                </Col>
              </Row>
              <div className="table-responsive" id="containerElement">
                <DataGridForm
                  dataSource={contenido}
                  keyExpr="idCustomer"
                  // remoteOperations={true}
                  columns={[
                    { caption: "Empresa", dataField: "firstName" },
                    { caption: "Email", dataField: "email" },
                    { caption: "Inicio actividad", dataField: "birthday" },
                    { caption: "CUIT", dataField: "idCUIT" },
                    { caption: "Usuario", dataField: "lastName" },
                    { edit: true, onTap: editarEmpresa },
                  ]}
                />

                <LoadPanel
                  visible={showLoading}
                  hideOnOutsideClick={showLoadingFalse}
                  width="200"
                  defaultPosition={{
                    of: "#containerElement",
                    at: "center",
                    my: "center",
                  }}
                />
              </div>
              {/* )} */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Empresas);
