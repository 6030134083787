/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import { loadingAction } from "../../../../redux/actions";
import { catchError, Fetch, FetchApi } from "../../../../utils/fetch";
import "./modificar-usuario.scss";
import { validateModel } from "./validationSchema";
import { Formik } from "formik";
import {
  AlertConfirm,
  AlertError,
  AlertInfo,
  AlertSuccess,
} from "../../../../components/alert";
import { object } from "prop-types";

function UsuarioCMS({ isLoadingAction, isLoading }) {
  const history = useHistory();
  const [user, setUser] = React.useState({});

  const { id } = useParams();

  React.useEffect(() => {
    if (id != null) getContenido();
  }, [id]);

  const submit=(values)=>{
    isLoadingAction(true);

          Fetch(`customuser/${id}`, "post", {
            ...values,
            UserToken: localStorage.getItem("tokenUser"),
          })
            .then((response) => {
              if (response.data.isPatient) {
                isLoadingAction(false);
                AlertConfirm("El usuario existe como paciente ¿Desea cambiar su rol a Administrador?","question",(response)=>{
                    if(response.isConfirmed)
                    {
                      submit({...values,isAdmin:true})
                    }
                })
              } else {
                if (response.data.error) {
                  AlertError(response.data.message);
                } else {
                  AlertSuccess(response.data.message);
                  history.push("/usuarios/cms/lista");
                }
              }
            })
            .catch((error) => {
              AlertError("Los datos no pudieron ser cambiados");
            })
            .finally((fin) => {
              isLoadingAction(false);
            });
  }

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch(`customuser/${id}`, "get", {
        idCustomer: id,
      });

      setUser({ ...data.user, role: data.user.role.id });
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={user}
        enableReinitialize
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);          
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          isValid,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>
                    {id ? "MODIFICAR" : "NUEVO"} USUARIO
                  </Card.Header>
                  <Card.Body>
                    <Row className="">
                      <Col md="6">
                        <label>Apellido </label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-custom input-group-text"
                              id="basic-addon1"
                            >
                              <i class="fas fa-user"></i>
                            </span>
                          </div>
                          <input
                            placeholder=""
                            type="text"
                            name="apellido"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.apellido}
                            className="form-control"
                          />
                        </div>

                        <div className="text-danger">{errors?.apellido}</div>
                      </Col>

                      <Col md="6">
                        <label>Nombre </label>
                        <div className="input-group mb-3">
                          <input
                            placeholder=""
                            onBlur={handleBlur}
                            type="text"
                            name="nombre"
                            onChange={handleChange}
                            value={values?.nombre}
                            className="form-control"
                          />
                        </div>

                        <div className="text-danger">{errors?.nombre}</div>
                      </Col>
                    </Row>

                    <Row className="">
                      <Col md="6">
                        <label>Mail </label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-custom input-group-text"
                              id="basic-addon1"
                            >
                              <i class="fas fa-at"></i>
                            </span>
                          </div>
                          <input
                            placeholder=""
                            type="text"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.email}
                            className="form-control"
                          />
                        </div>

                        <div className="text-danger">{errors?.email}</div>
                      </Col>

                      <Col md="6">
                        <label>Rol</label>
                        <div className="input-group mb-3">
                          <select
                            class="form-control"
                            name="role"
                            onChange={handleChange}
                            value={values?.role}
                          >
                            <option value="">-----</option>
                            <option value={3}>Aprobador</option>
                            <option value={4}>Creador</option>                            
                            <option value={6}>Admin</option>                            
                            <option value={7}>Super Admin</option>                            
                          </select>
                        </div>
                        <div className="text-danger">{errors?.role}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2" className="mt-31">
                        <Button
                          type="button"
                          onClick={() => handleSubmit()}
                          disabled={
                            isLoading ||
                            (id != null && Object.keys(user).length === 0) ||
                            Object.keys(values).length === 0 ||
                            !isValid
                          }
                          className="btn btn-success btn-icon"
                        >
                          <i className="fa fa-save mr-2"></i>Guardar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsuarioCMS);
