import * as Yup from "yup";

export const validateModel = Yup.object().shape({  
        data_fiscal : Yup.string().url().typeError("Campo incorrecto"),
        red_global : Yup.string().url().typeError("Campo incorrecto"),
        registro_nacional : Yup.string().url().typeError("Campo incorrecto"),
        consejo_argentino : Yup.string().url().typeError("Campo incorrecto"),
        itaes : Yup.string().url().typeError("Campo incorrecto"),
        safe_travels : Yup.string().typeError("Campo incorrecto"),
        facebook : Yup.string().url().typeError("Campo incorrecto"),
        linkedin : Yup.string().url().typeError("Campo incorrecto"),
        twitter : Yup.string().url().typeError("Campo incorrecto"),
        instagram : Yup.string().url().typeError("Campo incorrecto"),
        direccion_domicilio : Yup.string().required("Campo obligatorio").typeError("Campo incorrecto"),
        ubicacion : Yup.string().url().required("Campo obligatorio").typeError("Campo incorrecto"),
        telefono_domicilio : Yup.string().required("Campo obligatorio").typeError("Campo incorrecto"),
        titulo_politicas : Yup.string().required("Campo obligatorio").typeError("Campo incorrecto"),
        contenido_politicas : Yup.string().required("Campo obligatorio").typeError("Campo incorrecto"),
        titulo_terminos : Yup.string().required("Campo obligatorio").typeError("Campo incorrecto"),
        contenido_terminos : Yup.string().required("Campo obligatorio").typeError("Campo incorrecto"),
        intranet : Yup.string().url().typeError("Campo incorrecto"),
        contrato : Yup.object(),
});
