import React from 'react';
import { Link } from "react-router-dom";
import TopbarUserProfile from './profile';
import RightSidebar from '../right-sidebar';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux'

function Topbar(props) {
    const { user } = props
    const [state,setState]=React.useState({
        toggleClass: false,
        showRightSideBar: false,
    })

    
    // nav collapsed handle
    const handleToggleClass = (event) => {
        event.preventDefault();

        const rootNodeChildren = document.getElementById('root').children;
        for (let i = 0; i < rootNodeChildren.length; i++)
            if (rootNodeChildren[i].classList.contains('nav-collapsed'))
                rootNodeChildren[i].classList.remove('nav-collapsed')
            else
                rootNodeChildren[i].classList.add('nav-collapsed')
    }

   const showRightSideBarHandler=()=> {

        const currentState = state.showRightSideBar;
        setState({
            showRightSideBar: !currentState,
        });
    }

    return (
        <React.Fragment>
            {/* ============================================================== */}
            {/* 						Topbar Start 						   */}
            {/* ============================================================== */}
            {/* style={{ backgroundColor: this.props.user.empresa.id === 1 ? "#8acb48" : "#ff0000" }} */}
            <div className="top-bar primary-top-ba" style={{ backgroundColor: user?.empresa?.id === 1 ? "#ff0000" : '#8acb48' }}>
                <Container fluid>
                    <Row>
                        <Col>
                            <Link className="admin-logo bg-transparent" to="/" >
                                <span style={{ color: '#fff', fontWeight: 'bold', fontSize: '20px' }}>CMS</span>
                            </Link>
                            <div className="left-nav-toggle" onClick={(e) =>  handleToggleClass(e)}>
                                <Button as="a" variant="link" className="nav-collapse p-0">
                                    <i className="fa fa-bars" />
                                </Button>
                            </div>
                            <div className="left-nav-collapsed">
                                <Button as="a" variant="link" className="nav-collapsed p-0" onClick={(e) => handleToggleClass(e)}>
                                    <i className="fa fa-bars" />
                                </Button>
                            </div>
                        </Col>
                        <Col>
                            <ul className="list-inline top-right-nav">
                                {/* <TopbarUserMessage />
                        <TopbarUserNotification /> */}
                                {/* <TopbarRightSideBarBtn showRightSideBarHandler={this.showRightSideBarHandler} /> */}
                                <TopbarUserProfile />
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* ============================================================== */}
            {/*                        Topbar End                              */}
            {/* ============================================================== */}

            {/* ============================================================== */}
            {/*                   RightSidebar start                           */}
            {/* ============================================================== */}

            <RightSidebar show={state.showRightSideBar} />

            {/* ============================================================== */}
            {/*                    RightSidebar End                            */}
            {/* ============================================================== */}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {

    return {
        isAuthenticated: state.user.isAuthenticated,
        user: state.user.data,
    }
}
export default connect(mapStateToProps, null)(Topbar);
