/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "./ver.css";
import { FieldArray, Formik, useFormik } from "formik";
import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AlertSuccess } from "../../../../components/alert";
import ModalComponent from "../../../../components/modal";
import { loadingAction } from "../../../../redux/actions";
import { EstadoContenido } from "../../../../utils/constants";
import { catchError, Fetch } from "../../../../utils/fetch";
import MediaUploader from "../../../../utils/media-uploader";
import { validateModel } from "../validationSchema";
import { eliminarImagen } from "../../../../utils/tools";
import BadgeUpdate from "../../../../utils/badgeUpdate";

const VerBorradorHomeSlide = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const [formValues, setFormValues] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const permissions = useSelector((state) => state.permissions);
  const userType = useSelector((state) => state.user.data.role.type);
  //const canPublish = permissions['home-slides'].publicar.enabled;
  const idEmpresa = useSelector((state) => state.user.data.empresa.id);

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    validateForm,
  } = useFormik({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  const history = useHistory();
  React.useEffect(() => {
    if (id) {
      getHomeSlideById();
    }
  }, [id]);

  const getHomeSlideById = async () => {
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        "home-slides/" + id + "?_publicationState=preview",
        "get"
      );
      let { media, enlace } = data;
      setValues({ media: media, enlace: enlace });
      setFormValues({ media: media, enlace: enlace });
      validateForm({ media: media, enlace: enlace });
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    if (errors?.media != null || errors?.enlace != null) {
      dispatch(loadingAction(true));
      let errorKeys = Object.keys(errors);
      let errorToCatch = { message: "" };
      for (const error of errorKeys) {
        errorToCatch.message += `
        ${errors[error]}: ${error} <br>
              `;
      }
      dispatch(loadingAction(false));
      catchError(errorToCatch);
    } else {
      dispatch(loadingAction(true));

      values.published_at = null;
      //values.idEmpresa=idEmpresa;
      values.estado = EstadoContenido.EnRevision;

      try {
        await Fetch("home-slides/" + id, "put", values);

        dispatch(loadingAction(false));

        AlertSuccess("Home banner actualizado con éxito");

        history.push("/configuracion/home-slide/lista-borradores");
      } catch (error) {
        catchError(error);
        dispatch(loadingAction(false));
      }
    }
  };

  const onEnviarAprobar = async (values) => {
    if (errors?.media != null || errors?.enlace != null) {
      dispatch(loadingAction(true));
      let errorKeys = Object.keys(errors);
      let errorToCatch = { message: "" };
      for (const error of errorKeys) {
        errorToCatch.message += `
              ${errors[error]}: ${error} <br>
              `;
      }
      dispatch(loadingAction(false));
      catchError(errorToCatch);
    } else {
      dispatch(loadingAction(true));

      values.estado = EstadoContenido.EnAprobacion;

      try {
        await Fetch("home-slides/" + id, "put", values);

        dispatch(loadingAction(false));
        BadgeUpdate(idEmpresa, dispatch);

        if (userType == "aprobador") {
          AlertSuccess("Contenido enviado con éxito");
        } else {
          AlertSuccess(
            "Contenido enviado con éxito.<br/>Recuerde que falta la confirmación del aprobador.<br/>Nota: Por favor, notificar al responsable de publicar."
          );
        }

        if (userType == "creador") {
          history.push("/configuracion/home-slide/lista");
        } else {
          history.push("/configuracion/home-slide/listax");
        }
      } catch (error) {
        dispatch(loadingAction(false));
        catchError(error);
      }
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <ModalComponent isShow={showModal} setShowModal={setShowModal} />
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>
                    <h3>Información del banner</h3>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {values?.media && Object.keys(values.media).length > 0 && (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          <img
                            src={values.media.url}
                            alt="vista-previa"
                            className="img-fluid w-50"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="button"
                          style={{ marginBottom: "10px" }}
                          onClick={() => {
                            eliminarImagen(values?.media?.id).then(
                              (response) => {
                                setFieldValue("media", null, true);
                              }
                            );
                          }}
                          className="btn btn-danger btn-icon mt-2"
                        >
                          <i className="fa fa-trash-o " />
                          Eliminar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Seleccionar archivo</label>
                      <MediaUploader
                        setFieldValue={(name, value) => {
                          setFieldValue(name, value, true);
                        }}
                        field={"media"}
                        tipoMedia="imagen"
                        ancho={1600}
                        alto={580}
                        value={values?.media ? 100 : 0}
                        key={values?.media?.name}
                      />
                      {errors && errors.media && (
                        <div className="text-danger">{errors.media}</div>
                      )}
                      <span className="text-muted">
                        Se recomienda una imagen de 1600px de ancho x 580px de
                        alto. En formato JPG o PNG.
                      </span>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Enlace</label>
                      <input
                        type="text"
                        name="enlace"
                        onChange={handleChange}
                        className="form-control"
                        value={values.enlace}
                      />
                      <div className="text-danger">{errors?.enlace}</div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col className="col col-lg-2">
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-success btn-icon"
                    >
                      <i className="fa fa-floppy-o " />
                      Guardar
                    </Button>
                  </Col>
                  <Col className="col col-lg-3">
                    <Button
                      type="button"
                      onClick={() => onEnviarAprobar(values)}
                      disabled={isLoading}
                      className="btn btn-info btn-icon"
                    >
                      <i className="fa fa-share " />
                      Enviar aprobar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerBorradorHomeSlide;
