/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { FieldArray, Formik } from "formik";
import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AlertSuccess } from "../../../../components/alert";
import ModalComponent from "../../../../components/modal";
import { loadingAction } from "../../../../redux/actions";
import { EstadoContenido } from "../../../../utils/constants";
import { catchError, Fetch } from "../../../../utils/fetch";
import MediaUploader from "../../../../utils/media-uploader";
import { validateModel } from "../validationSchema";

const VerEliminarHomeSlide = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const [formValues, setFormValues] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);

  const history = useHistory();
  React.useEffect(() => {
    if (id) {
      getHomeSlideById();
    }
  }, [id]);

  const getHomeSlideById = async (values) => {
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        "home-slides/" + id + "?_eliminado=true",
        "get"
      );
      setFormValues(data);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = new Date();
    try {
      await Fetch("home-slides/" + id, "delete", values);

      dispatch(loadingAction(false));

      AlertSuccess("Banner eliminado con éxito");

      history.push("/configuracion/home-slide/lista");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onCancelar = async (values, type) => {
    dispatch(loadingAction(true));

    try {
      const params = {
        eliminado: false,
      };

      if (values.estado == EstadoContenido.EnRevision) {
        params.published_at = null;
    }

      await Fetch("home-slides/" + id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess("Se ha cancelado la eliminación del banner con éxito");

      history.push("/configuracion/home-slide/lista");

      if (values.estado == EstadoContenido.EnRevision) {
        history.push("/configuracion/home-slide/lista-borradores");
    } else {
        history.push("/configuracion/home-slide/lista");
    }
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const initialValues = {
    // tipo: "", //IMAGEN O VIDEO
    media: "",
    enlace: "",
  };

  const handleShowColors = () => {
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <ModalComponent isShow={showModal} setShowModal={setShowModal} />
              <Col>
                <Card>
                  <Card.Header>
                    <Row>
                      <Col>
                        <h3>Información del banner</h3>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    {values?.media && Object.keys(values.media).length > 0 && (
                      <Row>
                        <Col>
                          <Form.Group>
                            <img
                              src={values.media.url}
                              alt="vista-previa"
                              className="img-fluid w-50"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Enlace</label>
                          <input
                            disabled
                            type="text"
                            name="enlace"
                            onChange={handleChange}
                            className="form-control"
                            value={values.enlace}
                          />
                          <div className="text-danger">{errors?.enlace}</div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mt-5">
                      <Col className="col col-lg-2">
                        <Button
                          type="button"
                          onClick={() => handleSubmit()}
                          disabled={isLoading}
                          className="btn btn-danger btn-icon"
                        >
                          <i className="fas fa-trash" />
                          Eliminar
                        </Button>
                      </Col>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => onCancelar(values)}
                          disabled={isLoading}
                          className="btn btn-primary btn-icon"
                        >
                          <i className="fas fa-undo" />
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default VerEliminarHomeSlide;
