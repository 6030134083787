/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DataGridForm from "../../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../../redux/actions";
import { catchError, Fetch, FetchApi } from "../../../../../utils/fetch";
import { formatDate } from "../../../../../utils/formatDate";
import "./lista-pacientes.scss";
import CustomStore from "devextreme/data/custom_store";

function ListaPacientes({ isLoadingAction, isLoading }) {
  const [state, setState] = React.useState({
    filter: "",
    random: Math.random(),
  });
  const [contenido, setContenido] = React.useState(null);
  let history = useHistory();

  const normalizedColumns = (data) => {
    return data
      ?.map((item) => {
        if (!Array.isArray(item)) {
          return item;
        }

        return `${item[0]} ${item[1]} '${item[2]}'`;
      })
      ?.join(" ");
  };

  const normalizedColumnsV2 = (data) => {
    return data?.map((item) => {
      if (!Array.isArray(item)) {
        return { column: null, operator: item, value: null };
      }

      return { column: item[0], operator: item[1], value: item[2] };
    });
  };

  const normalizedSort = (data) => {
    return data
      ?.map((item) => {
        return `${item.selector} ${item.desc ? "desc" : "asc"}`;
      })
      .join(",");
  };

  React.useEffect(() => {
    const nuevoContenido = new CustomStore({
      key: "idCustomer",
      load(loadOptions) {
        const resultado = FetchApi(
          `customers/Api/v1/User/getCustomersFilterv2`,
          "post",
          {
            pageIndex: loadOptions.skip / loadOptions.take + 1,
            pageSize: loadOptions.take,
            filter: state.filter,
            random: state.random,
            filterColumns: loadOptions.filter,
            sort: loadOptions.sort,
          }
        ).then((response) => ({
          data: response?.data?.data?.CustomerGroup ?? [],
          totalCount: response?.data?.data?.TotalCount ?? 0,
        }));

        return resultado;
      },
    });

    setContenido(nuevoContenido);
  }, [state.random]);

  function FActualizacion(data) {
    return formatDate(data?.fecha_nacimiento);
  }

  function Accion(data) {
    return (
      <>
        <Link
          to={"/modificar-usuario/" + data.id}
          className="btn btn-sm btn btn-success"
        >
          <i className="fa fa-pencil" />
        </Link>{" "}
      </>
    );
  }

  const onChange = async (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onBuscar = () => {
    setState({ ...state, random: Math.random() });
  };

  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      onBuscar();
    }
  }

  function Genero(data) {
    return (
      <>
        <span>{data.sex === "H" ? "Hombre" : "Mujer"}</span>
      </>
    );
  }

  const editarUsuario = (e) => {
    const data = e.row.data;
    history.push("/modificar-usuario/" + data.idCustomer);
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>Usuarios registrados</Card.Header>
            <Card.Body>
              <Row className="mb-15">
                <Col md="3">
                  <input
                    placeholder="Buscar usuario"
                    type="text"
                    name="filter"
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    value={state.filter}
                    class="form-control"
                  />
                </Col>
                <Col md="2">
                  <Button onClick={onBuscar}>
                    <i className="fa fa-search mr-2"></i>Filtrar
                  </Button>
                </Col>
              </Row>

              <div className="table-responsive">
                <DataGridForm
                  dataSource={contenido}
                  remoteOperations={true}
                  columns={[
                    { caption: "Apellido", dataField: "lastName" },
                    { caption: "Nombre", dataField: "firstName" },
                    { caption: "Email", dataField: "email" },
                    { caption: "Fecha de nacimiento", dataField: "birthday" },
                    { caption: "DNI", dataField: "dni" },
                    {
                      caption: "Género",
                      alignment: "left",
                      render: "Genero",
                      cellRender: { Genero },
                    },
                    { edit: true, onTap: editarUsuario },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPacientes);
