import * as Yup from 'yup';

export const validateModel = Yup.object().shape({
    emailContact: Yup.string().required('Campo obligatorio'),
    emailDisplay: Yup.string().required('Campo obligatorio'),
    emailInfo: Yup.string().required('Campo obligatorio'),
    emailValidation: Yup.string().required('Campo obligatorio'),
    reminder: Yup.number().required('Campo obligatorio'),
    retryAttempts: Yup.number().required('Campo obligatorio'),
    retryDelay: Yup.number().required('Campo obligatorio'),
    timeout: Yup.number().required('Campo obligatorio'),
});