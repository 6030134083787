import * as Yup from "yup";

export const validateModel = Yup.object().shape({
  atencionTelefonica: Yup.string().required("Campo obligatorio"),
  centralTurno: Yup.string().required("Campo obligatorio"),
  whatsapp: Yup.string().required("Campo obligatorio"),
  novedad: Yup.string().when("estadoNovedad", {
    is: (value) => value === true || value === "true",
    then: Yup.string()
      .required("Campo obligatorio")
      .max(
        100,
        "Las novedades pueden tener una extensión máxima de 100 caracteres. Modifique el texto para poder publicarlo."
      ),
    otherwise: Yup.string().max(
      100,
      "Las novedades pueden tener una extensión máxima de 100 caracteres. Modifique el texto para poder publicarlo."
    ),
  }),
  estadoNovedad: Yup.mixed().required("Campo obligatorio"), // Permitir tanto boolean como string
});
