import MenuAdmin from "../data/menu-hb/menu-admin.json";
import MenuSuperAdmin from "../data/menu-hb/menu-super-admin.json";
import MenuAprobador from "../data/menu-hb/menu-aprobador.json";
import MenuCreador from "../data/menu-hb/menu-creador.json";
import MenuTeleperformance from "../data/menu-hb/menu-teleperformance.json";
import { AlertConfirm } from "../components/alert";
import { Fetch } from "./fetch";

export const removeSpecial=(string)=>{
  return string;
}


export const removeSpecialJodit=(string)=>{
  var regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u26FF\u2700-\u27BF\uD83C][\uDC00-\uDFFF]|\u24C2[\uFE0F]?|[\uD83C][\uDDE6-\uDDFF]{1,2}|[\uD83D][\uDC00-\uDE4F\uDE80-\uDEFF\uDEC0-\uDEF8\uDF00-\uDF9F\uDFA0-\uDFDF\uDFE0-\uDFEF\uDFF0-\uDFFD\uDFFF]/g;
    var cleanedString = string.replace(/[^\x00-\x7F\u00F1\u00D1áéíóúÁÉÍÓÚ¡?¿!()]/g, '').replace(/"/g, '\\"');
    return cleanedString;
  }


export const eliminarImagen=async (id)=>{    
  return new Promise((resolve,reject)=>{
    AlertConfirm("La imagen se eliminará permanentemente y no será posible recuperarla.","question", (response) => {        
      if (response.isConfirmed) {
        Fetch(`upload/files/${id}`, "delete").then((data)=>{resolve(data)});          
      }else{
        reject(1)
      }
  });      
  });
  
}

export const permitRoute = (path) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const rol = userData?.role;

  let menu = null;
  let result = false;
  
  if (!userData) return false;

  switch (rol.type) {
    case "creador":
      menu = JSON.stringify(MenuCreador)?.toLowerCase();
      result = menu.includes(path?.toLowerCase());
      break;
    case "aprobador":
      menu = JSON.stringify(MenuAprobador)?.toLowerCase();
      result = menu.includes(path?.toLowerCase());
      break;
    case "teleperformance":
      menu = JSON.stringify(MenuTeleperformance)?.toLowerCase();
      result = menu.includes(path?.toLowerCase());
      break;
    case "admin":
      menu = JSON.stringify(MenuAdmin)?.toLowerCase();
      result = menu.includes(path?.toLowerCase());
      break;
    case "superadmin":
      menu = JSON.stringify(MenuSuperAdmin)?.toLocaleLowerCase();
      result = menu.includes(path?.toLocaleLowerCase());
      break;
      default:
        break;
  }

  return result;
}