import React from 'react'
import { FormularioFooterComponent } from '../../../components/configuracion/footer/formulario-footer'
import { EstadoContenido } from '../../../utils/constants'

const FooterPublicadoPage = () => {
  return (
    <FormularioFooterComponent estado={EstadoContenido.Publicado}/>
  )
}

export default FooterPublicadoPage
