import * as Yup from 'yup';

const validateModel = Yup.object().shape({

    idCUIT: Yup.string().required('Campo obligatorio'),
    firstName: Yup.string().required('Campo obligatorio'),
    lastName: Yup.string().required('Campo obligatorio'),
    socialSecurity: Yup.string().required('Campo obligatorio'),
    email: Yup.string().required('Campo obligatorio'),
    phone: Yup.number().optional('Solo números').typeError("Solo números"),
    mobile: Yup.number().optional('Solo números').typeError("Solo números"),
    
    
});

export default validateModel;
// const validate = values => {

//     const errors = {};
 
//     if (!values.idCUIT) { 
//       errors.idCUIT = 'Requerido'; 
//     }

//     if(!values.firstName)
//     {
//         errors.firstName= 'Requerido';
//     }

//     if(!values.lastName)
//     {
//         errors.lastName= 'Requerido';
//     }

//     if(!values.socialSecurity)
//     {
//         errors.socialSecurity= 'Requerido';
//     }

//     if(!values.email)
//     {
//         errors.email= 'Requerido';
//     }


 
//     return errors; 
//   };

//   export default validate;