import React from 'react'

export default function Footer(){
    
    return (
        <section className="main-content">
            <footer className="footer">
                <span>Copyright © 2022 Plan de Salud Hospital Británico</span>
            </footer>
        </section>
    )
}

