import React from 'react'
import BackendLayout from './backend-layout';
import AuthLayout from './auth-layout';
import { loadMessages, locale } from "devextreme/localization";
import esMessages from "devextreme/localization/messages/es.json";

export default function Layout(props) {

    React.useEffect(() => {
        loadMessages(esMessages);
        locale(navigator.language);
    })

    let layout = 'backend';

    if (layout === 'auth')
        return (<AuthLayout name={layout}>{props.children}</AuthLayout>)

    else if (layout === 'backend')
        return (<BackendLayout name={layout}>{props.children}</BackendLayout>)

    else
        return (<BackendLayout name={layout}>{props.children}</BackendLayout>)

}

