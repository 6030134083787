/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import DataGridForm from "../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../redux/actions";
import { FetchApi } from "../../../../utils/fetch";
import "./logs-actividad.scss";
import TiposLog from "../../../../data/tipo-log.json";
import { Formik } from "formik";
import { validateModel } from "./validationSchema";
import CustomStore from "devextreme/data/custom_store";
import MonthPickerInput from "react-month-picker-input";
require("react-month-picker-input/dist/react-month-picker-input.css");

function LogsActividad({ isLoadingAction, isLoading }) {
  const [state, setState] = React.useState({
    description: "",
    monthYear: "",
    idType: 0,
    random: Math.random(),
  });
  const [contenido, setContenido] = React.useState(null);
  const [totalData, setTotalData] = React.useState(null);

  const getContenido = async (values) => {
    if (values !== null) {
      isLoadingAction(true);
      const response = await FetchApi(
        `log/Api/v1/LogData/GetLogActivity`,
        "post",
        {
          monthYear: state.monthYear,
          pageIndex: 0,
          pageSize: 10,
        }
      );
      setTotalData(response?.data?.data?.totalCount);

      setContenido(
        new CustomStore({
          key: "m",
          load(loadOptions) {
            isLoadingAction(true);
            const resultado = FetchApi(
              `log/Api/v1/LogData/GetLogActivity`,
              "post",
              {
                monthYear: state.monthYear,
                pageIndex: loadOptions.skip / loadOptions.take + 1,
                pageSize: loadOptions.take,
                description: values.description,
                idType: values.idType,
              }
            )
              .then((response) => {
                return {
                  data: response?.data?.data?.logs,
                  totalCount: response?.data?.data?.totalCount,
                };
              })
              .finally(() => {
                isLoadingAction(false);
              });
            return resultado;
          },
        })
      );
    }
  };

  const downloadExcel = async (values) => {
    const { data } = await FetchApi(
      `log/Api/v1/LogData/GetLogActivityExcel`,
      "post",
      {
        monthYear: state.monthYear,
        description: values.description,
        pageIndex: 1,
        pageSize: totalData,
      }
    );

    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.data.file}`;
    const downloadLink = document.createElement("a");
    const fileName = "logsActividades.xlsx";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={state}
        enableReinitialize
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          getContenido(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>Ultimos logs del sistema</Card.Header>
                  <Card.Body>
                    <Row className="mb-15">
                      <Col md="2">
                        <label>Mes</label>
                        <MonthPickerInput
                          lang="es"
                          year={new Date().getFullYear()}
                          month={new Date().getMonth()}
                          onChange={function (
                            maskedValue,
                            selectedYear,
                            selectedMonth
                          ) {
                            setState({
                              ...state,
                              monthYear:
                                "01/" +
                                (selectedMonth + 1)
                                  .toString()
                                  .padStart(2, "00") +
                                "/" +
                                selectedYear,
                            });
                          }}
                        />
                      </Col>
                      <Col md="2">
                        <label>Tipo log.</label>
                        <select
                          class="form-control"
                          name="idType"
                          onChange={handleChange}
                          value={values?.idType}
                        >
                          <option selected="selected" value="">
                            Seleccione uno
                          </option>
                          <option selected="selected" value="0">
                            Todos
                          </option>
                          {TiposLog.map((item, index) => {
                            return (
                              <option value={item.value}>{item.text}</option>
                            );
                          })}
                        </select>
                        <div className="text-danger">{errors?.idType}</div>
                      </Col>
                      <Col md="2">
                        <label>Descripción</label>
                        <input
                          type="text"
                          class="form-control"
                          name="description"
                          onChange={handleChange}
                          value={values?.description}
                        />
                      </Col>
                      <Col md="2" className="mt-31">
                        <label>&nbsp;</label>
                        <Button onClick={handleSubmit}>
                          <i className="fa fa-search mr-2"></i>Buscar
                        </Button>
                      </Col>
                    </Row>

                    <div className="table-responsive">
                      <DataGridForm
                        dataSource={contenido}
                        remoteOperations={true}
                        columns={[
                          { caption: "Fecha", dataField: "fecha" },
                          { caption: "Descripción", dataField: "description" },
                          { caption: "Código", dataField: "code" },
                          { caption: "Doctor", dataField: "doctor" },
                        ]}
                      />
                      {contenido && (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => downloadExcel(values)}
                        >
                          <i className="fa fa-file-excel-o" /> Exportar excel
                        </button>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsActividad);
