/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { FieldArray, Formik } from "formik";
import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AlertSuccess } from "../../../../components/alert";
import ModalComponent from "../../../../components/modal";
import { loadingAction } from "../../../../redux/actions";
import { EstadoContenido } from "../../../../utils/constants";
import { catchError, Fetch } from "../../../../utils/fetch";
import MediaUploader from "../../../../utils/media-uploader";
import { validateModel } from "../validationSchema";
import { eliminarImagen } from "../../../../utils/tools";
import BadgeUpdate from "../../../../utils/badgeUpdate";

const VerHomeSlideX = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const [formValues, setFormValues] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const idEmpresa = useSelector((state) => state.user.data.empresa.id);

  const history = useHistory();
  React.useEffect(() => {
    if (id) {
      getHomeSlideById();
    }
  }, [id]);

  const getHomeSlideById = async (values) => {
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        "home-slides/" +
          id +
          "?_publicationState=preview&published_at_null=true",
        "get"
      );
      setFormValues(data);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    const idPadre = values.idPadre;
    const idToApi = idPadre ? idPadre : id;
    if (idPadre) {
      delete values.caption.id;
      values.caption.botones.forEach(function (v) {
        delete v.id;
      });
    }

    dispatch(loadingAction(true));

    values.published_at = new Date();
    try {
      await Fetch("home-slides/aprobar/" + idToApi, "put", values);
      BadgeUpdate(idEmpresa, dispatch);
      AlertSuccess("Home banner actualizado con éxito");
      dispatch(loadingAction(false));

      history.push("/configuracion/home-slide/lista");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onCancelar = async () => {
    dispatch(loadingAction(true));

    try {
      const params = {
        published_at: null,
        estado: EstadoContenido.EnRevision,
      };

      await Fetch("home-slides/" + id, "put", params);

      dispatch(loadingAction(false));
      BadgeUpdate(idEmpresa, dispatch);
      AlertSuccess("Se ha cancelado la publicación de la sección con éxito");

      history.push("/configuracion/home-slide/lista-borradores");
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const initialValues = {
    //tipo: "", //IMAGEN O VIDEO
    media: "",
    enlace: "",
  };

  const handleShowColors = () => {
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <ModalComponent isShow={showModal} setShowModal={setShowModal} />
              <Col>
                <Card>
                  <Card.Header>
                    <Row>
                      <Col>
                        <h3>Información del banner</h3>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    {values?.media && Object.keys(values.media).length > 0 && (
                      <>
                        <Row>
                          <Col>
                            <Form.Group>
                              <img
                                src={values.media.url}
                                alt="vista-previa"
                                className="img-fluid w-50"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button
                              type="button"
                              style={{ marginBottom: "10px" }}
                              onClick={() => {
                                eliminarImagen(values?.media?.id).then(
                                  (response) => {
                                    setFieldValue("media", "", false);
                                  }
                                );
                              }}
                              className="btn btn-danger btn-icon mt-2"
                            >
                              <i className="fa fa-trash-o " />
                              Eliminar
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Seleccionar archivo</label>
                          <MediaUploader
                            setFieldValue={setFieldValue}
                            field={"media"}
                            tipoMedia="imagen"
                            ancho={1600}
                            alto={580}
                            value={values?.media ? 100 : 0}
                            key={values?.media}
                          />
                          {errors && errors.media && (
                            <div className="text-danger">{errors.media}</div>
                          )}
                          <span className="text-muted">
                            Se recomienda una imagen de 1600px de ancho x 580px
                            de alto. En formato JPG o PNG.
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Enlace</label>
                          <input
                            type="text"
                            name="enlace"
                            onChange={handleChange}
                            className="form-control"
                            value={values.enlace}
                          />
                          <div className="text-danger">{errors?.enlace}</div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mt-5">
                      <Col className="col col-lg-2">
                        <Button
                          type="button"
                          onClick={() => handleSubmit()}
                          disabled={isLoading}
                          className="btn btn-success btn-icon"
                        >
                          <i className="fa fa-floppy-o" />
                          Publicar
                        </Button>
                      </Col>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => onCancelar()}
                          disabled={isLoading}
                          className="btn btn-primary btn-icon"
                        >
                          <i className="fa fa-undo " />
                          Por revisar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default VerHomeSlideX;
