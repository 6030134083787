/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArray, Formik } from "formik";
import React from "react";
import { Button, Card, Col, Form, Row, Accordion } from "react-bootstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AlertConfirm, AlertSuccess } from "../../../../components/alert";
import ModalComponent from "../../../../components/modal";
import { loadingAction } from "../../../../redux/actions";
import { EstadoContenido } from "../../../../utils/constants";
import { catchError, Fetch } from "../../../../utils/fetch";
import MediaUploader from "../../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { eliminarImagen } from "../../../../utils/tools";
//import './ver.css';

const EditarAyuda = ({ isLoadingAction, isLoading }) => {
  const { id, codigo } = useParams();
  const dispatch = useDispatch();
  //const isLoading = useSelector(state => state.loading)
  const [formValues, setFormValues] = React.useState({});
  const [configuration, setConfiguration] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  // const permissions = useSelector(state => state.permissions)
  // const canPublish = permissions['home-slides'].publicar.enabled;
  const idEmpresa = useSelector((state) => state.user.data.empresa.id);

  const history = useHistory();
  React.useEffect(() => {
    if (id) {
      getHomeSlideById();
    }
  }, [id]);

  const getHomeSlideById = async () => {
    try {
      isLoadingAction(true);

      const ayuda = await Fetch(
        "ayudas/" + id + "?_publicationState=preview",
        "get"
      );
      const ayudaConfiguracion = await Fetch(
        `ayuda-configuracions/?codigo=${codigo}&_publicationState=preview`,
        "get"
      );

      Promise.all([ayuda, ayudaConfiguracion]).then((response) => {
        const help = response[0].data;
        const helpSetting = response[1].data[0];

        //help.multimedia = help.multimedia === null ? '' : help.multimedia
        help.requeridoDescripcionCorta = helpSetting?.descripcionCorta;
        help.requeridoMultimedia = helpSetting?.multimedia;
        help.requeridoPreguntaDescripcionCorta =
          helpSetting?.preguntaDescripcionCorta;
        //help.requeridoRespuestaMultimedia = helpSetting.respuestaMultimedia

        help?.preguntas.map((item) => {
          item.requeridoPreguntaDescripcionCorta =
            helpSetting?.preguntaDescripcionCorta;
          // item.respuestas.map((itemR) => {
          //     itemR.requeridoRespuestaMultimedia = helpSetting.respuestaMultimedia
          // })
        });

        setFormValues(help);
        setConfiguration(helpSetting);
        isLoadingAction(false);
      });
    } catch (error) {
      catchError(error);
      isLoadingAction(false);
    }
  };

  const submit = async (values) => {
    isLoadingAction(true);

    //values.published_at = null;
    //values.idPadre = values.id;
    //values.estado = EstadoContenido.EnRevision
    values.idEmpresa = idEmpresa;

    try {
      values.preguntas.map((itemP) => {
        delete itemP.id;
        itemP.respuestas.map((itemR) => {
          delete itemR.id;
        });
      });

      await Fetch("ayudas/" + values.id, "put", values);

      isLoadingAction(false);

      AlertSuccess("Ayuda actualizada con éxito");

      history.push("/configuracion/ayuda/lista");
    } catch (error) {
      catchError(error);
      isLoadingAction(false);
    }
  };

  const initialValues = {
    codigo: "",
    titulo: "",
    descripcionCorta: "",
    multimedia: {},
    tipoMultimedia: "I",
    preguntas: [
      {
        titulo: "",
        descripcionCorta: "",
        respuestas: [
          {
            descripcion: "",
            multimedia: {},
            tipoMultimedia: "I",
          },
        ],
      },
    ],
    idEmpresa: 0,
    requeridoDescripcionCorta: false,
    requeridoMultimedia: false,
    requeridoPreguntaDescripcionCorta: false,
    requeridoRespuestaMultimedia: false,
  };

  const eliminarOpcionPregunta = (index, remove) => {
    AlertConfirm(
      `Usted está por borrar la pregunta seleccionada
        ¿Desea continuar?`,
      "question",
      function (response) {
        if (response.isConfirmed) {
          remove(index);
        }
      }
    );
  };

  const eliminarOpcionRespuesta = (index, remove) => {
    AlertConfirm(
      `Usted está por borrar la respuesta seleccionada
        ¿Desea continuar?`,
      "question",
      function (response) {
        if (response.isConfirmed) {
          remove(index);
        }
      }
    );
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setValues,
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Card>
                  <Card.Header>Editar ayuda</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>
                            <b>Título</b>
                          </label>
                          <input
                            type="text"
                            name="titulo"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.titulo}
                          />
                          <div className="text-danger">{errors?.titulo}</div>
                        </div>
                      </Col>
                    </Row>

                    {/* {configuration?.descripcionCorta && */}
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>
                            <b>Descripción corta</b>
                          </label>
                          <textarea
                            name="descripcionCorta"
                            className="form-control"
                            onChange={handleChange}
                            value={values?.descripcionCorta}
                          ></textarea>
                          <div className="text-danger">
                            {errors?.descripcionCorta}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* {configuration?.multimedia && */}
                    <>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>
                              <b>Tipo de multimedia</b>
                            </label>
                            <select
                              name="tipoMultimedia"
                              onChange={handleChange}
                              className="form-control"
                              value={values?.tipoMultimedia}
                            >
                              <option value="I">IMAGEN</option>
                              {/* <option value="V">VIDEO</option> */}
                            </select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>
                              <b>Seleccionar archivo</b>
                            </label>
                            {/* {values?.tipoMultimedia === "I" ||
                            values?.tipoMultimedia === null ||
                            values?.tipoMultimedia == "" ? ( */}
                            <>
                              <MediaUploader
                                value={values?.multimedia ? 100 : 0}
                                key={values?.multimedia}
                                setFieldValue={setFieldValue}
                                field={"multimedia"}
                                tipoMedia="imagen"
                                ancho={1200}
                                alto={600}
                              />
                              <span className="text-muted">
                                Se recomienda una imagen de 1200px de ancho x
                                600px de alto. En formato JPG o PNG.
                              </span>
                              <div className="text-danger">
                                {errors?.multimedia}
                              </div>
                            </>
                            {/* ) : (
                              <>
                                <MediaUploader
                                  value={values?.multimedia ? 100 : 0}
                                  key={values?.multimedia}
                                  setFieldValue={setFieldValue}
                                  field={"multimedia"}
                                  tipoMedia="video"
                                  ancho={1920}
                                  alto={1080}
                                />
                                <span className="text-muted">
                                  Se recomienda un video de 1920px de ancho x
                                  1080px de alto. En formato MP4 ó WEBM.
                                </span>
                                <div className="text-danger">
                                  {errors?.multimedia}
                                </div>
                              </>
                            )} */}
                          </Form.Group>
                        </Col>
                      </Row>
                      {values?.multimedia &&
                        Object.keys(values?.multimedia)?.length > 0 && (
                          <>
                            <Row>
                              <Col>
                                <Form.Group>
                                  <img
                                    src={values?.multimedia?.url}
                                    alt="vista-previa"
                                    className="img-fluid w-50"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Button
                                  type="button"
                                  style={{ marginBottom: "10px" }}
                                  onClick={() => {
                                    eliminarImagen(values?.multimedia.id).then(
                                      (response) => {
                                        setFieldValue("multimedia", "", false);
                                      }
                                    );
                                  }}
                                  className="btn btn-danger btn-icon mt-2"
                                >
                                  <i className="fa fa-trash-o " />
                                  Eliminar
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                    </>
                    {/* } */}

                    {/* Preguntas */}
                    <Row>
                      <Col>
                        <h2>Preguntas</h2>
                        <FieldArray name="preguntas">
                          {({
                            move,
                            swap,
                            push,
                            remove,
                            insert,
                            unshift,
                            pop,
                            form,
                          }) => {
                            return (
                              <>
                                {values.preguntas &&
                                  values.preguntas.length > 0 &&
                                  values.preguntas.map((data, index) => {
                                    return (
                                      <Row key={index}>
                                        <Col>
                                          <Accordion defaultActiveKey="-1">
                                            <Card className="border mb-2">
                                              <Card.Header className="d-flex justify-content-between">
                                                <Accordion.Toggle
                                                  as={Button}
                                                  variant="link"
                                                  eventKey="0"
                                                >
                                                  {index + 1}
                                                </Accordion.Toggle>
                                                <button
                                                  type="button"
                                                  className="btn btn-default"
                                                  onClick={() =>
                                                    eliminarOpcionPregunta(
                                                      index,
                                                      remove
                                                    )
                                                  }
                                                >
                                                  X
                                                </button>
                                              </Card.Header>
                                              <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                  <Row>
                                                    <Col>
                                                      {/* <div className="form-group">
                                                                                                                <label><b>Título</b></label>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    name={`preguntas.[${index}].titulo`}
                                                                                                                    className="form-control"
                                                                                                                    onChange={handleChange}
                                                                                                                    onBlur={handleBlur}
                                                                                                                    value={values?.preguntas[index]?.titulo}
                                                                                                                />
                                                                                                                {errors &&
                                                                                                                    errors.preguntas &&
                                                                                                                    errors.preguntas[index] &&
                                                                                                                    errors.preguntas[index].titulo &&
                                                                                                                    (
                                                                                                                        <div className="text-danger">{errors?.preguntas[index]?.titulo}</div>
                                                                                                                    )
                                                                                                                }
                                                                                                            </div> */}
                                                    </Col>
                                                  </Row>

                                                  {/* {configuration?.preguntaDescripcionCorta && */}
                                                  <Row>
                                                    <Col>
                                                      <div className="form-group">
                                                        <label>
                                                          <b>Pregunta</b>
                                                        </label>
                                                        <textarea
                                                          name={`preguntas.[${index}].descripcionCorta`}
                                                          className="form-control"
                                                          onChange={
                                                            handleChange
                                                          }
                                                          value={
                                                            values?.preguntas[
                                                              index
                                                            ]?.descripcionCorta
                                                          }
                                                        ></textarea>
                                                        {errors &&
                                                          errors.preguntas &&
                                                          errors.preguntas[
                                                            index
                                                          ] &&
                                                          errors.preguntas[
                                                            index
                                                          ]
                                                            .descripcionCorta && (
                                                            <div className="text-danger">
                                                              {
                                                                errors
                                                                  ?.preguntas[
                                                                  index
                                                                ]
                                                                  ?.descripcionCorta
                                                              }
                                                            </div>
                                                          )}
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                  {/* } */}

                                                  {/* RESPUESTAS */}
                                                  <Row>
                                                    <Col>
                                                      <h2>Respuestas</h2>
                                                      <FieldArray
                                                        name={
                                                          "preguntas[" +
                                                          index +
                                                          "].respuestas"
                                                        }
                                                      >
                                                        {({
                                                          move,
                                                          swap,
                                                          push,
                                                          remove,
                                                          insert,
                                                          unshift,
                                                          pop,
                                                          form,
                                                        }) => {
                                                          return (
                                                            <>
                                                              {values.preguntas[
                                                                index
                                                              ].respuestas &&
                                                                values
                                                                  .preguntas[
                                                                  index
                                                                ].respuestas
                                                                  .length > 0 &&
                                                                values.preguntas[
                                                                  index
                                                                ].respuestas.map(
                                                                  (
                                                                    dataR,
                                                                    indexR
                                                                  ) => {
                                                                    return (
                                                                      <Row
                                                                        key={
                                                                          indexR
                                                                        }
                                                                      >
                                                                        <Col>
                                                                          <Accordion defaultActiveKey="-1">
                                                                            <Card className="border mb-2">
                                                                              <Card.Header className="d-flex justify-content-between">
                                                                                <Accordion.Toggle
                                                                                  as={
                                                                                    Button
                                                                                  }
                                                                                  variant="link"
                                                                                  eventKey="0"
                                                                                >
                                                                                  {indexR +
                                                                                    1}
                                                                                </Accordion.Toggle>
                                                                                <button
                                                                                  type="button"
                                                                                  className="btn btn-default"
                                                                                  onClick={() =>
                                                                                    eliminarOpcionRespuesta(
                                                                                      indexR,
                                                                                      remove
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  X
                                                                                </button>
                                                                              </Card.Header>
                                                                              <Accordion.Collapse eventKey="0">
                                                                                <Card.Body>
                                                                                  <Row>
                                                                                    <Col>
                                                                                      <div className="form-group">
                                                                                        <label>
                                                                                          <b>
                                                                                            Respuesta
                                                                                          </b>
                                                                                        </label>
                                                                                        <textarea
                                                                                          name={`preguntas.[${index}].respuestas[${indexR}].descripcion`}
                                                                                          className="form-control"
                                                                                          onChange={
                                                                                            handleChange
                                                                                          }
                                                                                          value={
                                                                                            values
                                                                                              ?.preguntas[
                                                                                              index
                                                                                            ]
                                                                                              ?.respuestas[
                                                                                              indexR
                                                                                            ]
                                                                                              .descripcion
                                                                                          }
                                                                                        ></textarea>
                                                                                        {errors &&
                                                                                          errors.preguntas &&
                                                                                          errors
                                                                                            .preguntas[
                                                                                            index
                                                                                          ] &&
                                                                                          errors
                                                                                            .preguntas[
                                                                                            index
                                                                                          ]
                                                                                            .respuestas &&
                                                                                          errors
                                                                                            .preguntas[
                                                                                            index
                                                                                          ]
                                                                                            .respuestas[
                                                                                            indexR
                                                                                          ] &&
                                                                                          errors
                                                                                            .preguntas[
                                                                                            index
                                                                                          ]
                                                                                            .respuestas[
                                                                                            indexR
                                                                                          ]
                                                                                            .descripcion && (
                                                                                            <div className="text-danger">
                                                                                              {
                                                                                                errors
                                                                                                  ?.preguntas[
                                                                                                  index
                                                                                                ]
                                                                                                  ?.respuestas[
                                                                                                  indexR
                                                                                                ]
                                                                                                  ?.descripcion
                                                                                              }
                                                                                            </div>
                                                                                          )}
                                                                                      </div>
                                                                                    </Col>
                                                                                  </Row>
                                                                                  <Row>
                                                                                    <Col>
                                                                                      <Form.Group>
                                                                                        <label>
                                                                                          <b>
                                                                                            Tipo
                                                                                            de
                                                                                            multimedia
                                                                                          </b>
                                                                                        </label>
                                                                                        <select
                                                                                          name={`preguntas.[${index}].respuestas[${indexR}].tipoMultimedia`}
                                                                                          onChange={
                                                                                            handleChange
                                                                                          }
                                                                                          className="form-control"
                                                                                          value={
                                                                                            values
                                                                                              ?.preguntas[
                                                                                              index
                                                                                            ]
                                                                                              ?.respuestas[
                                                                                              indexR
                                                                                            ]
                                                                                              .tipoMultimedia
                                                                                          }
                                                                                        >
                                                                                          <option value="I">
                                                                                            IMAGEN
                                                                                          </option>
                                                                                          <option value="V">
                                                                                            VIDEO
                                                                                          </option>
                                                                                        </select>
                                                                                      </Form.Group>
                                                                                    </Col>
                                                                                  </Row>
                                                                                  <Row>
                                                                                    <Col>
                                                                                      <Form.Group>
                                                                                        <label>
                                                                                          <b>
                                                                                            Seleccionar
                                                                                            archivo
                                                                                          </b>
                                                                                        </label>
                                                                                        {values
                                                                                          ?.preguntas[
                                                                                          index
                                                                                        ]
                                                                                          ?.respuestas[
                                                                                          indexR
                                                                                        ]
                                                                                          .tipoMultimedia ===
                                                                                          "I" ||
                                                                                        values
                                                                                          ?.preguntas[
                                                                                          index
                                                                                        ]
                                                                                          ?.respuestas[
                                                                                          indexR
                                                                                        ]
                                                                                          .tipoMultimedia ===
                                                                                          null ? (
                                                                                          <>
                                                                                            <MediaUploader
                                                                                              setFieldValue={
                                                                                                setFieldValue
                                                                                              }
                                                                                              value={
                                                                                                values
                                                                                                  .preguntas[
                                                                                                  index
                                                                                                ]
                                                                                                  .respuestas[
                                                                                                  indexR
                                                                                                ]
                                                                                                  .multimedia
                                                                                                  ? 100
                                                                                                  : 0
                                                                                              }
                                                                                              key={
                                                                                                values
                                                                                                  .preguntas[
                                                                                                  index
                                                                                                ]
                                                                                                  .respuestas[
                                                                                                  indexR
                                                                                                ]
                                                                                                  .multimedia
                                                                                              }
                                                                                              field={`preguntas.[${index}].respuestas[${indexR}].multimedia`}
                                                                                              tipoMedia="imagen"
                                                                                              ancho={
                                                                                                1200
                                                                                              }
                                                                                              alto={
                                                                                                600
                                                                                              }
                                                                                            />
                                                                                            <span className="text-muted">
                                                                                              Se
                                                                                              recomienda
                                                                                              una
                                                                                              imagen
                                                                                              de
                                                                                              1200px
                                                                                              de
                                                                                              ancho
                                                                                              x
                                                                                              600px
                                                                                              de
                                                                                              alto.
                                                                                              En
                                                                                              formato
                                                                                              JPG
                                                                                              o
                                                                                              PNG.
                                                                                            </span>
                                                                                            {errors &&
                                                                                              errors.preguntas &&
                                                                                              errors
                                                                                                .preguntas[
                                                                                                index
                                                                                              ] &&
                                                                                              errors
                                                                                                .preguntas[
                                                                                                index
                                                                                              ]
                                                                                                .respuestas &&
                                                                                              errors
                                                                                                .preguntas[
                                                                                                index
                                                                                              ]
                                                                                                .respuestas[
                                                                                                indexR
                                                                                              ] &&
                                                                                              errors
                                                                                                .preguntas[
                                                                                                index
                                                                                              ]
                                                                                                .respuestas[
                                                                                                indexR
                                                                                              ]
                                                                                                .multimedia && (
                                                                                                <div className="text-danger">
                                                                                                  {
                                                                                                    errors
                                                                                                      ?.preguntas[
                                                                                                      index
                                                                                                    ]
                                                                                                      ?.respuestas[
                                                                                                      indexR
                                                                                                    ]
                                                                                                      ?.multimedia
                                                                                                  }
                                                                                                </div>
                                                                                              )}
                                                                                          </>
                                                                                        ) : (
                                                                                          <>
                                                                                            <MediaUploader
                                                                                              setFieldValue={
                                                                                                setFieldValue
                                                                                              }
                                                                                              value={
                                                                                                values
                                                                                                  .preguntas[
                                                                                                  index
                                                                                                ]
                                                                                                  .respuestas[
                                                                                                  indexR
                                                                                                ]
                                                                                                  .multimedia
                                                                                                  ? 100
                                                                                                  : 0
                                                                                              }
                                                                                              key={
                                                                                                values
                                                                                                  .preguntas[
                                                                                                  index
                                                                                                ]
                                                                                                  .respuestas[
                                                                                                  indexR
                                                                                                ]
                                                                                                  .multimedia
                                                                                              }
                                                                                              //setFieldValue={(name,value)=>{setFieldValue(name,value,false);setValues({...values,[name]:value})}}
                                                                                              field={`preguntas.[${index}].respuestas[${indexR}].multimedia`}
                                                                                              tipoMedia="video"
                                                                                              ancho={
                                                                                                1920
                                                                                              }
                                                                                              alto={
                                                                                                1080
                                                                                              }
                                                                                            />
                                                                                            <span className="text-muted">
                                                                                              Se
                                                                                              recomienda
                                                                                              un
                                                                                              video
                                                                                              de
                                                                                              1920px
                                                                                              de
                                                                                              ancho
                                                                                              x
                                                                                              1080px
                                                                                              de
                                                                                              alto.
                                                                                              En
                                                                                              formato
                                                                                              MP4
                                                                                              ó
                                                                                              WEBM.
                                                                                            </span>
                                                                                            {errors &&
                                                                                              errors.preguntas &&
                                                                                              errors
                                                                                                .preguntas[
                                                                                                index
                                                                                              ] &&
                                                                                              errors
                                                                                                .preguntas[
                                                                                                index
                                                                                              ]
                                                                                                .respuestas &&
                                                                                              errors
                                                                                                .preguntas[
                                                                                                index
                                                                                              ]
                                                                                                .respuestas[
                                                                                                indexR
                                                                                              ] &&
                                                                                              errors
                                                                                                .preguntas[
                                                                                                index
                                                                                              ]
                                                                                                .respuestas[
                                                                                                indexR
                                                                                              ]
                                                                                                .multimedia && (
                                                                                                <div className="text-danger">
                                                                                                  {
                                                                                                    errors
                                                                                                      ?.preguntas[
                                                                                                      index
                                                                                                    ]
                                                                                                      ?.respuestas[
                                                                                                      indexR
                                                                                                    ]
                                                                                                      ?.multimedia
                                                                                                  }
                                                                                                </div>
                                                                                              )}
                                                                                          </>
                                                                                        )}
                                                                                      </Form.Group>
                                                                                    </Col>
                                                                                  </Row>
                                                                                  {dataR.multimedia !==
                                                                                    null && (
                                                                                    <>
                                                                                      <Row>
                                                                                        <Col>
                                                                                          <Form.Group>
                                                                                            <img
                                                                                              src={
                                                                                                dataR
                                                                                                  .multimedia
                                                                                                  .url
                                                                                              }
                                                                                              alt="vista-previa"
                                                                                              className="img-fluid w-50"
                                                                                            />
                                                                                          </Form.Group>
                                                                                        </Col>
                                                                                      </Row>
                                                                                      {dataR
                                                                                        .multimedia
                                                                                        ?.id !=
                                                                                        null && (
                                                                                        <Row>
                                                                                          <Col>
                                                                                            <Button
                                                                                              type="button"
                                                                                              style={{
                                                                                                marginBottom:
                                                                                                  "10px",
                                                                                              }}
                                                                                              onClick={() => {
                                                                                                eliminarImagen(
                                                                                                  dataR
                                                                                                    .multimedia
                                                                                                    .id
                                                                                                ).then(
                                                                                                  (
                                                                                                    response
                                                                                                  ) => {
                                                                                                    setFieldValue(
                                                                                                      `preguntas.[${index}].respuestas[${indexR}].multimedia`,
                                                                                                      "",
                                                                                                      false
                                                                                                    );

                                                                                                    values.preguntas[
                                                                                                      index
                                                                                                    ].respuestas[
                                                                                                      indexR
                                                                                                    ].multimedia =
                                                                                                      "";
                                                                                                    setValues(
                                                                                                      {
                                                                                                        ...values,
                                                                                                      }
                                                                                                    );
                                                                                                  }
                                                                                                );
                                                                                              }}
                                                                                              className="btn btn-danger btn-icon mt-2"
                                                                                            >
                                                                                              <i className="fa fa-trash-o " />
                                                                                              Eliminar
                                                                                            </Button>
                                                                                          </Col>
                                                                                        </Row>
                                                                                      )}
                                                                                    </>
                                                                                  )}
                                                                                </Card.Body>
                                                                              </Accordion.Collapse>
                                                                            </Card>
                                                                          </Accordion>
                                                                        </Col>
                                                                      </Row>
                                                                    );
                                                                  }
                                                                )}
                                                              <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                onClick={() =>
                                                                  push({
                                                                    id: "",
                                                                    descripcion:
                                                                      "",
                                                                    multimedia:
                                                                      "",
                                                                    tipoMultimedia:
                                                                      "I",
                                                                  })
                                                                }
                                                              >
                                                                Añadir respuesta
                                                              </button>
                                                            </>
                                                          );
                                                        }}
                                                      </FieldArray>
                                                    </Col>
                                                  </Row>
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>
                                          </Accordion>
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    push({
                                      id: "",
                                      titulo: "Agregar pregunta",
                                      descripcionCorta: "",
                                      respuestas: [],
                                    })
                                  }
                                >
                                  Añadir pregunta
                                </button>
                              </>
                            );
                          }}
                        </FieldArray>
                      </Col>
                    </Row>
                    {/* {JSON.stringify(errors)} */}
                    {/* <br/>
                                        <br/> */}
                    {/* {JSON.stringify(values)} */}
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-success btn-icon mt-2"
                    >
                      <i className="fa fa-floppy-o " />
                      Guardar
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarAyuda);
