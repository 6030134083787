import { Formik } from "formik";
import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { validateModel } from "./validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AlertError, AlertInfo } from "../../alert";
import { Fetch, catchError } from "../../../utils/fetch";
import { loadingAction } from "../../../redux/actions";
import { EstadoContenido } from "../../../utils/constants";
import LayoutJodit from "../../../components/customizer/layout-jodit";
import JoditEditor, { Jodit } from "jodit-react";
import MediaUploader from "../../../utils/media-uploader";
import "./style.scss"

export const FormularioFooterComponent = ({ estado }) => {
  const initialValues = {
    data_fiscal: "",
    red_global: "",
    registro_nacional: "",
    consejo_argentino: "",
    itaes: "",
    safe_travels: "",
    facebook: "",
    linkedin: "",
    twitter: "",
    instagram: "",
    direccion_domicilio: "",
    telefono_domicilio: "",
    ubicacion:"",
    titulo_politicas: "",
    contenido_politicas: "",
    titulo_terminos: "",
    contenido_terminos: "",
    intranet: "",
    contrato: {},
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const userType = useSelector((state) => state.user.data.role.type);
  const [formValues, setFormValues] = React.useState({});
  const permissions = useSelector((state) => state.permissions);
  const [layoutJodit, setLayoutJodit] = React.useState("PC");
  // const canPublish = permissions["footer"].publicar.enabled;

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    dispatch(loadingAction(true));
    try {
      let dataTemp = null;
      switch (estado) {
        case EstadoContenido.EnRevision:
          const { data: dataRevision } = await Fetch(
            `footers?estado=ER`,
            "get"
          );
          dataTemp = dataRevision;
          break;
        case EstadoContenido.Publicado:
          const { data: dataPublicado } = await Fetch(
            `footers?estado=PU`,
            "get"
          );
          dataTemp = dataPublicado;
          break;
        case EstadoContenido.EnAprobacion:
          const { data: dataAprobacion } = await Fetch(
            `footers?estado=EA`,
            "get"
          );
          dataTemp = dataAprobacion;
          break;
        default:
      }

      // const { data: dataPublicado } = await Fetch(
      //   `footers`,
      //   "get"
      // );
      // const  dataTemp = dataPublicado;

      const registro=dataTemp[0]
      if (registro != null) {
        const except=["creado_por","actualizado_por"]
        Object.keys(registro).forEach((key) => {
          if (registro[key] === null && except.indexOf(key)===-1) {
            registro[key] = "";
          }
        });
        setFormValues(registro)
      };

      // if (estado === EstadoContenido.Publicado) {
      //   // if(data[0]!=null)
      //   //   setFormValues(data[0]);
      // } else {
      //   let model = dataTemp[0];

      //   if (model) {
      //     model.data_fiscal = model.data_fiscalTmp;
      //     model.red_global = model.red_globalTmp;
      //     model.registro_nacional = model.registro_nacionalTmp;
      //     model.consejo_argentino = model.consejo_argentinoTmp;
      //     model.itaes = model.itaesTmp;
      //     model.safe_travels = model.safe_travelsTmp;
      //     model.facebook = model.facebookTmp;
      //     model.linkedin = model.linkedinTmp;
      //     model.twitter = model.twitterTmp;
      //     model.instagram = model.instagramTmp;
      //     model.direccion_domicilio = model.direccion_domicilioTmp;
      //     model.telefono_domicilio = model.telefono_domicilioTmp;
      //     model.titulo_politicas = model.titulo_politicasTmp;
      //     model.contenido_politicas = model.contenido_politicasTmp;
      //     model.titulo_terminos = model.titulo_terminosTmp;
      //     model.contenido_terminos = model.contenido_terminosTmp;
      //     model.intranet = model.intranetTmp;
      //     model.contrato = model.contratoTmp;

      //     setFormValues(model);
      //   }
      // }

      dispatch(loadingAction(false));
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const config = React.useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      language: "es",
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: process.env.REACT_APP_BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];

          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  const onActualizar = async (handleSubmit, values) => {
    handleSubmit();
    try {
      dispatch(loadingAction(true));

      const model = {
        ...values,
        estado: EstadoContenido.EnRevision,
      };

      if (values.estado === "ER") {
        await Fetch("footers/" + values.id, "put", model);
      } else {
        await Fetch("footers/estado/borrador/", "post", model);
      }
      dispatch(loadingAction(false));

      AlertInfo("Contenido enviado a revisión con éxito.");
      history.push("/configuracion/footer/borrador");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onEnviarAprobar = async (handleSubmit, values) => {
    handleSubmit();

    try {
      dispatch(loadingAction(true));

      const model = {
        ...values,
        estado: EstadoContenido.EnAprobacion,
      };

      await Fetch("footers/" + formValues.id, "put", model);
      dispatch(loadingAction(false));

      if (userType == "aprobador") {
        AlertInfo("Contenido enviado con éxito");
        history.push("/configuracion/footer/aprobar");
      } else {
        AlertInfo(
          "Contenido actualizado con éxito.<br/>Recuerde que falta la confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar."
        );
      }
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onPublicar = async (handleSubmit, values) => {
    handleSubmit();

    try {
      // if (!canPublish) {
      //   AlertInfo("No tiene permiso para realizar esta acción");
      //   return;
      // }

      // values.red_global = "";
      // values.registro_nacional = "";
      // values.consejo_argentino = "";
      // values.itaes = "";
      // values.safe_travels = "";
      // values.facebook = "";
      // values.linkedin = "";
      // values.twitter = "";
      // values.instagram = "";
      // values.direccion_domicilio = "";
      // values.telefono_domicilio = "";
      // values.titulo_politicas = "";
      // values.contenido_politicas = "";
      // values.intranet = "";
      // values.contrato = {};
      // values.estado = EstadoContenido.Publicado;

      const model = { ...values, estado: EstadoContenido.Publicado };

      dispatch(loadingAction(true));

      await Fetch("footers/estado/publicar", "post", model);
      dispatch(loadingAction(false));

      AlertInfo("Contenido publicado con éxito");
      history.push("/configuracion/footer/publicado");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onEnviarARevision = async (handleSubmit, values) => {
    handleSubmit();

    try {
      // if (!canPublish) {
      //   AlertInfo("No tiene permiso para realizar esta acción");
      //   return;
      // }

      dispatch(loadingAction(true));

      values.estado = EstadoContenido.EnRevision;
      await Fetch("footers/" + values.id, "put", values);
      dispatch(loadingAction(false));

      AlertInfo("Se ha cancelado la publicación de la sección con éxito");
      history.push("/configuracion/footer/borrador");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const handleChangeJoiEditor = (data, field, fn) => {
    fn(field, data, false);
  };

  const isDisabled = (val) => {
    const {
      red_global,
      registro_nacional,
      consejo_argentino,
      itaes,
      safe_travels,
      facebook,
      linkedin,
      twitter,
      instagram,
      direccion_domicilio,
      telefono_domicilio,
      titulo_politicas,
      contenido_politicas,
      intranet,
      contrato,
    } = val;

    // if (estadoNovedad === false || estadoNovedad === "false") {
    //   return (
    //     atencionTelefonica === "" || centralTurno === "" || whatsapp === ""
    //   );
    // } else if (estadoNovedad === true || estadoNovedad === "true") {
    //   return (
    //     atencionTelefonica === "" ||
    //     centralTurno === "" ||
    //     whatsapp === "" ||
    //     novedad === ""
    //   );
    // }

    return false;
  };

  function Botones({ handleSubmit, values,validateForm }) {
    if (estado === EstadoContenido.Publicado) {
      return (
        <Row className="mt-5">
          <Col className="col col-lg-2">
            <Button
              type="button"
              onClick={async () => {
                const isValidate=await validateForm()
                const keys=Object.keys(isValidate)
                if(keys.length>0)
                {
                  AlertError("Debe de completar todos los campos")
                  return
                }
                onActualizar(handleSubmit, values)
              }}
              disabled={isLoading}
              className="btn btn-success btn-icon"
            >
              <i className="fa fa-floppy-o " />
              Guardar
            </Button>
          </Col>
        </Row>
      );
    }

    if (estado === EstadoContenido.EnRevision) {
      let buttonDisabled = isDisabled(values);
      if (values.estado != "ER") {
        buttonDisabled = true;
      }

      return (
        <Row className="mt-5">
          <Col className="col col-lg-2">
            <Button
              type="button"
              onClick={async () =>{ 
                const isValidate=await validateForm()
                const keys=Object.keys(isValidate)
                if(keys.length>0)
                {
                  AlertError("Debe de completar todos los campos")
                  return
                }  
                onActualizar(handleSubmit, values)
              }}
              disabled={buttonDisabled}
              // className="btn btn-success btn-icon"
              className={"btn btn-success btn-icon no-hover"}
            >
              <i className="fa fa-floppy-o" />
              Guardar
            </Button>
          </Col>
          <Col className="col col-lg-3">
            <Button
              type="button"
              onClick={async () => {
                const isValidate=await validateForm()
                const keys=Object.keys(isValidate)
                if(keys.length>0)
                {
                  AlertError("Debe de completar todos los campos")
                  return
                }
                onEnviarAprobar(handleSubmit, values)
              }}
              disabled={buttonDisabled}
              className="btn btn-info btn-icon no-hover2"
            >
              <i className="fa fa-share " />
              Enviar aprobar
            </Button>
          </Col>
        </Row>
      );
    }

    if (estado === EstadoContenido.EnAprobacion) {
      let buttonDisabled = isDisabled(values);
      if (values.estado != "EA") {
        buttonDisabled = true;
      }

      return (
        <Row className="mt-5">
          <Col className="col col-lg-2">
            <Button
              type="button"
              onClick={async () =>{ 
                const isValidate=await validateForm()
                const keys=Object.keys(isValidate)
                if(keys.length>0)
                {
                  AlertError("Debe de completar todos los campos")
                  return
                }
                onPublicar(handleSubmit, values)
              }}
              disabled={buttonDisabled}
              className="btn btn-success btn-icon no-hover"
            >
              <i className="fa fa-floppy-o " />
              Publicar
            </Button>
          </Col>
          <Col className="col col-lg-3">
            <Button
              type="button"
              onClick={async () =>{
                const isValidate=await validateForm()
                const keys=Object.keys(isValidate)
                if(keys.length>0)
                {
                  AlertError("Debe de completar todos los campos")
                  return
                }
                 onEnviarARevision(handleSubmit, values)                 
                }
                }
              disabled={buttonDisabled}
              className="btn btn-primary btn-icon no-hover3"
            >
              <i className="fa fa-undo " />
              Por revisar
            </Button>
          </Col>
        </Row>
      );
    }
  }

  //PARA EN REVISION
  if (estado === EstadoContenido.EnRevision) {
    return (
      <>
        <Formik
          initialValues={formValues || initialValues}
          enableReinitialize
          validationSchema={validateModel}
          onSubmit={(values, { setSubmitting }) => {
            //submit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            validateForm
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Card>
                    <Card.Header style={{ paddingBottom: "0px" }}>
                      <Row>
                        <Col>
                          <h3>Footer</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                        <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body style={{ paddingTop: "0px" }}>
                      {/* Primera sección global */}
                      <div>
                        <Row>
                          <Col>
                            <h2
                              style={{
                                fontWeight: "normal",
                                marginBottom: "15px",
                              }}
                            >
                              Logos e hipervínculos
                            </h2>
                          </Col>
                        </Row>
                        {/* Sección 1 */}
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Data fiscal
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="data_fiscal"
                                onChange={handleChange}
                                className="form-control"
                                value={values.data_fiscal}
                              />
                              <div className="text-danger">
                                {errors?.data_fiscal}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Red Global de Hospitales Verdes y Saludables
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="red_global"
                                onChange={handleChange}
                                className="form-control"
                                value={values.red_global}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.red_global}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* Sección 2 */}

                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Registro nacional de base de datos
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="registro_nacional"
                                onChange={handleChange}
                                className="form-control"
                                value={values.registro_nacional}
                              />
                              <div className="text-danger">
                                {errors?.registro_nacional}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Consejo Argentino de hospitales de
                                Colectividades
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="consejo_argentino"
                                onChange={handleChange}
                                className="form-control"
                                value={values.consejo_argentino}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.consejo_argentino}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* Sección 3 */}

                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                ITAES
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="itaes"
                                onChange={handleChange}
                                className="form-control"
                                value={values.itaes}
                              />
                              <div className="text-danger">{errors?.itaes}</div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Safe Travels
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="safe_travels"
                                onChange={handleChange}
                                className="form-control"
                                value={values.safe_travels}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.safe_travels}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>

                      {/* Segunda sección global */}
                      <div>
                        <Row>
                          <Col>
                          <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <h2
                              style={{
                                fontWeight: "normal",
                                marginBottom: "15px",
                              }}
                            >
                              Redes sociales
                            </h2>
                          </Col>
                        </Row>
                        {/* Sección 1 */}
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Facebook
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="facebook"
                                onChange={handleChange}
                                className="form-control"
                                value={values.facebook}
                              />
                              <div className="text-danger">
                                {errors?.facebook}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                LinkedIn
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="linkedin"
                                onChange={handleChange}
                                className="form-control"
                                value={values.linkedin}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.linkedin}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* Sección 2 */}

                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Twitter
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="twitter"
                                onChange={handleChange}
                                className="form-control"
                                value={values.twitter}
                              />
                              <div className="text-danger">
                                {errors?.twitter}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Instagram
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="instagram"
                                onChange={handleChange}
                                className="form-control"
                                value={values.instagram}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.instagram}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>

                      {/* Tercera sección */}

                      <div>
                        <Row>
                          <Col>
                          <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <h2
                              style={{
                                fontWeight: "normal",
                                marginBottom: "15px",
                              }}
                            >
                              Domicilio legal
                            </h2>
                          </Col>
                        </Row>
                        {/* Sección 1 */}
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Dirección
                              </label>
                              <input
                                type="text"
                                name="direccion_domicilio"
                                onChange={handleChange}
                                className="form-control"
                                value={values.direccion_domicilio}
                              />
                              <div className="text-danger">
                                {errors?.direccion_domicilio}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Teléfono
                              </label>
                              <input
                                type="text"
                                name="telefono_domicilio"
                                onChange={handleChange}
                                className="form-control"
                                value={values.telefono_domicilio}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.telefono_domicilio}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                URL Google Maps
                              </label>
                              <input
                                type="text"
                                name="ubicacion"
                                onChange={handleChange}
                                className="form-control"
                                value={values.ubicacion}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.ubicacion}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>

                      {/* Cuarta sección */}

                      <div>
                        <Row>
                          <Col>
                          <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <h2
                              style={{
                                fontWeight: "normal",
                                marginBottom: "15px",
                              }}
                            >
                              Legales - Popup
                            </h2>
                          </Col>
                        </Row>
                        {/* Sección 1 */}
                        <Row>
                          <Col sm={12}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Políticas de privacidad
                              </label>
                              <br />
                              <label>Título</label>
                              <input
                                type="text"
                                name="titul_politicas"
                                onChange={handleChange}
                                className="form-control"
                                value={values.titulo_politicas}
                              />
                              <div className="text-danger">
                                {errors?.titulo_politicas}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            className={`${layoutJodit == "MOBILE" && "col-6"}`}
                          >
                            <Form.Group>
                              <label>Contenido</label>
                              <LayoutJodit
                                setLayoutJodit={setLayoutJodit}
                                layoutJodit={layoutJodit}
                              />
                              <JoditEditor
                                config={config}
                                value={values.contenido_politicas}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(newContent) =>
                                  handleChangeJoiEditor(
                                    newContent,
                                    "contenido_politicas",
                                    setFieldValue
                                  )
                                }
                              />

                              <div className="text-danger">
                                {errors?.contenido_politicas}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Términos y condiciones
                              </label>
                              <br />
                              <label>Título</label>
                              <input
                                type="text"
                                name="titulo_terminos"
                                onChange={handleChange}
                                className="form-control"
                                value={values.titulo_terminos}
                              />
                              <div className="text-danger">
                                {errors?.titulo_terminos}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            className={`${layoutJodit == "MOBILE" && "col-6"}`}
                          >
                            <Form.Group>
                              <label>Contenido</label>
                              <LayoutJodit
                                setLayoutJodit={setLayoutJodit}
                                layoutJodit={layoutJodit}
                              />
                              <JoditEditor
                                config={config}
                                value={values.contenido_terminos}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(newContent) =>
                                  handleChangeJoiEditor(
                                    newContent,
                                    "contenido_terminos",
                                    setFieldValue
                                  )
                                }
                              />

                              <div className="text-danger">
                                {errors?.contenido_terminos}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Intranet
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="intranet"
                                onChange={handleChange}
                                className="form-control"
                                value={values.intranet}
                              />
                              <div className="text-danger">
                                {errors?.intranet}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>

                      {/* Quinta sección */}

                      <div>
                        <Row>
                          <Col>                            
                          <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <h2
                              style={{
                                fontWeight: "normal",
                                marginBottom: "15px",
                              }}
                            >
                              Contrato de adhesión de los socios
                            </h2>
                          </Col>
                        </Row>
                        {/* Sección 1 */}
                        <Row>
                          <Col>
                            <Form.Group>
                              {/* <label>PDF</label> */}
                              {values?.contrato?.url!=null ?
                            <div className="preview-button">
                                <div className="preview-component">
                                <a className="btn btn-info" href={values?.contrato?.url} target="_blank">
                                    <i className="fa fa-eye " />                                
                                </a>
                              <span>Previsualizar archivo actual</span>
                              </div>
                              <span>Cargar el nuevo archivo que remplazará el existente</span>
                            </div>:
                            <div style={{marginTop:"10px"}}></div>
                            }  
                              
                              <MediaUploader
                                value={values?.contrato ? 100 : 0}
                                key={values?.contrato}
                                setFieldValue={(name, value) => {
                                  setFieldValue(name, value, false);
                                  // setValues({ ...values, [name]: value });
                                }}
                                field={"contrato"}
                                tipoMedia="archivo"
                              />
                              {errors && errors.contrato && (
                                <div className="text-danger">
                                  {errors.contrato}
                                </div>
                              )}
                              {/* <span className="text-muted">
                              Se recomienda una imagen de 300px de ancho x 300px
                              de alto
                            </span> */}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label>Formatos permitidos: Archivo PDF.</label>
                          </Col>
                        </Row>
                      </div>

                      <Botones
                        handleSubmit={() => handleSubmit()}
                        values={values}
                        validateForm={validateForm}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </>
    );
  }

  //PARA POR APROBAR
  if (estado === EstadoContenido.EnAprobacion) {
    return (
      <>
        <Formik
          initialValues={formValues || initialValues}
          enableReinitialize
          validationSchema={validateModel}
          onSubmit={(values, { setSubmitting }) => {
            //submit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            validateForm
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Card>
                    <Card.Header style={{ paddingBottom: "0px" }}>
                      <Row>
                        <Col>
                          <h3>Footer</h3>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                        <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body style={{ paddingTop: "0px" }}>
                      {/* Primera sección global */}
                      <div>
                        <Row>
                          <Col>
                            <h2
                              style={{
                                fontWeight: "normal",
                                marginBottom: "15px",
                              }}
                            >
                              Logos e hipervínculos
                            </h2>
                          </Col>
                        </Row>
                        {/* Sección 1 */}
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Data fiscal
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="data_fiscal"
                                onChange={handleChange}
                                className="form-control"
                                value={values.data_fiscal}
                              />
                              <div className="text-danger">
                                {errors?.data_fiscal}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Red Global de Hospitales Verdes y Saludables
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="red_global"
                                onChange={handleChange}
                                className="form-control"
                                value={values.red_global}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.red_global}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* Sección 2 */}

                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Registro nacional de base de datos
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="registro_nacional"
                                onChange={handleChange}
                                className="form-control"
                                value={values.registro_nacional}
                              />
                              <div className="text-danger">
                                {errors?.registro_nacional}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Consejo Argentino de hospitales de
                                Colectividades
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="consejo_argentino"
                                onChange={handleChange}
                                className="form-control"
                                value={values.consejo_argentino}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.consejo_argentino}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* Sección 3 */}

                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                ITAES
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="itaes"
                                onChange={handleChange}
                                className="form-control"
                                value={values.itaes}
                              />
                              <div className="text-danger">{errors?.itaes}</div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Safe Travels
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="safe_travels"
                                onChange={handleChange}
                                className="form-control"
                                value={values.safe_travels}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.safe_travels}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>

                      {/* Segunda sección global */}
                      <div>
                        <Row>
                          <Col>
                          <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <h2
                              style={{
                                fontWeight: "normal",
                                marginBottom: "15px",
                              }}
                            >
                              Redes sociales
                            </h2>
                          </Col>
                        </Row>
                        {/* Sección 1 */}
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Facebook
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="facebook"
                                onChange={handleChange}
                                className="form-control"
                                value={values.facebook}
                              />
                              <div className="text-danger">
                                {errors?.facebook}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                LinkedIn
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="linkedin"
                                onChange={handleChange}
                                className="form-control"
                                value={values.linkedin}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.linkedin}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        {/* Sección 2 */}

                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Twitter
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="twitter"
                                onChange={handleChange}
                                className="form-control"
                                value={values.twitter}
                              />
                              <div className="text-danger">
                                {errors?.twitter}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Instagram
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="instagram"
                                onChange={handleChange}
                                className="form-control"
                                value={values.instagram}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.instagram}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>

                      {/* Tercera sección */}

                      <div>
                        <Row>
                          <Col>
                          <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <h2
                              style={{
                                fontWeight: "normal",
                                marginBottom: "15px",
                              }}
                            >
                              Domicilio legal
                            </h2>
                          </Col>
                        </Row>
                        {/* Sección 1 */}
                        <Row>
                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Dirección
                              </label>
                              <input
                                type="text"
                                name="direccion_domicilio"
                                onChange={handleChange}
                                className="form-control"
                                value={values.direccion_domicilio}
                              />
                              <div className="text-danger">
                                {errors?.direccion_domicilio}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Teléfono
                              </label>
                              <input
                                type="text"
                                name="telefono_domicilio"
                                onChange={handleChange}
                                className="form-control"
                                value={values.telefono_domicilio}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.telefono_domicilio}
                              </div>
                            </Form.Group>
                          </Col>

                          <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                URL Google Maps
                              </label>
                              <input
                                type="text"
                                name="ubicacion"
                                onChange={handleChange}
                                className="form-control"
                                value={values.ubicacion}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.ubicacion}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>

                      {/* Cuarta sección */}

                      <div>
                        <Row>
                          <Col>
                          <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <h2
                              style={{
                                fontWeight: "normal",
                                marginBottom: "15px",
                              }}
                            >
                              Legales - Popup
                            </h2>
                          </Col>
                        </Row>
                        {/* Sección 1 */}
                        <Row>
                          <Col sm={12}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Políticas de privacidad
                              </label>
                              <br />
                              <label>Título</label>
                              <input
                                type="text"
                                name="titulo_politicas"
                                onChange={handleChange}
                                className="form-control"
                                value={values.titulo_politicas}
                              />
                              <div className="text-danger">
                                {errors?.titulo_politicas}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            className={`${layoutJodit == "MOBILE" && "col-6"}`}
                          >
                            <Form.Group>
                              <label>Contenido</label>
                              <LayoutJodit
                                setLayoutJodit={setLayoutJodit}
                                layoutJodit={layoutJodit}
                              />
                              <JoditEditor
                                config={config}
                                value={values.contenido_politicas}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(newContent) =>
                                  handleChangeJoiEditor(
                                    newContent,
                                    "contenido_politicas",
                                    setFieldValue
                                  )
                                }
                              />

                              <div className="text-danger">
                                {errors?.contenido_politicas}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Términos y condiciones
                              </label>
                              <br />
                              <label>Título</label>
                              <input
                                type="text"
                                name="titulo_terminos"
                                onChange={handleChange}
                                className="form-control"
                                value={values.titulo_terminos}
                              />
                              <div className="text-danger">
                                {errors?.titulo_terminos}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            className={`${layoutJodit == "MOBILE" && "col-6"}`}
                          >
                            <Form.Group>
                              <label>Contenido</label>
                              <LayoutJodit
                                setLayoutJodit={setLayoutJodit}
                                layoutJodit={layoutJodit}
                              />
                              <JoditEditor
                                config={config}
                                value={values.contenido_terminos}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(newContent) =>
                                  handleChangeJoiEditor(
                                    newContent,
                                    "contenido_terminos",
                                    setFieldValue
                                  )
                                }
                              />

                              <div className="text-danger">
                                {errors?.contenido_terminos}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                Intranet
                              </label>
                              <br />
                              <label>URL</label>
                              <input
                                type="text"
                                name="intranet"
                                onChange={handleChange}
                                className="form-control"
                                value={values.intranet}
                              />
                              <div className="text-danger">
                                {errors?.intranet}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>

                      {/* Quinta sección */}

                      <div>
                        <Row>
                          <Col>
                          <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <h2
                              style={{
                                fontWeight: "normal",
                                marginBottom: "15px",
                              }}
                            >
                              Contrato de adhesión de los socios
                            </h2>
                          </Col>
                        </Row>
                        {/* Sección 1 */}
                        <Row>
                          <Col>
                            <Form.Group>
                            {values?.contrato?.url!=null ?
                            <div className="preview-button">
                                <div className="preview-component">
                                <a className="btn btn-info" href={values?.contrato?.url} target="_blank">
                                    <i className="fa fa-eye " />                                
                                </a>
                              <span>Previsualizar archivo actual</span>
                              </div>
                              <span>Cargar el nuevo archivo que remplazará el existente</span>
                            </div>:
                            <div style={{marginTop:"10px"}}></div>
                            }  
                              <MediaUploader
                                value={values?.contrato ? 100 : 0}
                                key={values?.contrato}
                                setFieldValue={(name, value) => {
                                  setFieldValue(name, value, false);
                                  // setValues({ ...values, [name]: value });
                                }}
                                field={"contrato"}
                                tipoMedia="archivo"
                              />
                              {errors && errors.contrato && (
                                <div className="text-danger">
                                  {errors.contrato}
                                </div>
                              )}
                              {/* <span className="text-muted">
                              Se recomienda una imagen de 300px de ancho x 300px
                              de alto
                            </span> */}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label>Formatos permitidos: Archivo PDF.</label>
                          </Col>
                        </Row>
                      </div>

                      <Botones
                        handleSubmit={() => handleSubmit()}
                        values={values}
                        validateForm={validateForm}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </>
    );
  }

  //PARA PUBLICADOS
  return (
    <>
      <Formik
        initialValues={formValues}
        enableReinitialize
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          //submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          validateForm
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Card>
                  <Card.Header style={{ paddingBottom: "0px" }}>
                    <Row>
                      <Col>
                        <h3>Footer</h3>                                                
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {/* <hr
                          style={{ backgroundColor: "black", height: "1px" }}
                        /> */}
                        <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body style={{ paddingTop: "0px" }}>
                    {/* Primera sección global */}
                    <div>
                      <Row>
                        <Col>
                          <h2
                            style={{
                              fontWeight: "normal",
                              marginBottom: "15px",
                            }}
                          >
                            Logos e hipervínculos
                          </h2>
                        </Col>
                      </Row>
                      {/* Sección 1 */}
                      <Row>
                        <Col sm={12} md={6}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              Data fiscal
                            </label>
                            <br />
                            <label>URL</label>
                            <input
                              type="text"
                              name="data_fiscal"
                              onChange={handleChange}
                              className="form-control"
                              value={values.data_fiscal}
                            />
                            <div className="text-danger">
                              {errors?.data_fiscal}
                            </div>
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              Red Global de Hospitales Verdes y Saludables
                            </label>
                            <br />
                            <label>URL</label>
                            <input
                              type="text"
                              name="red_global"
                              onChange={handleChange}
                              className="form-control"
                              value={values.red_global}
                            />
                            <div className="text-danger">
                              {errors?.red_global}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* Sección 2 */}

                      <Row>
                        <Col sm={12} md={6}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              Registro nacional de base de datos
                            </label>
                            <br />
                            <label>URL</label>
                            <input
                              type="text"
                              name="registro_nacional"
                              onChange={handleChange}
                              className="form-control"
                              value={values.registro_nacional}
                            />
                            <div className="text-danger">
                              {errors?.registro_nacional}
                            </div>
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              Consejo Argentino de hospitales de Colectividades
                            </label>
                            <br />
                            <label>URL</label>
                            <input
                              type="text"
                              name="consejo_argentino"
                              onChange={handleChange}
                              className="form-control"
                              value={values.consejo_argentino}
                              // value={values.centralTurno}
                            />
                            <div className="text-danger">
                              {errors?.consejo_argentino}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* Sección 3 */}

                      <Row>
                        <Col sm={12} md={6}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>ITAES</label>
                            <br />
                            <label>URL</label>
                            <input
                              type="text"
                              name="itaes"
                              onChange={handleChange}
                              className="form-control"
                              value={values.itaes}
                            />
                            <div className="text-danger">
                              {errors?.atencionTelefonica}
                            </div>
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              Safe Travels
                            </label>
                            <br />
                            <label>URL</label>
                            <input
                              type="text"
                              name="safe_travels"
                              onChange={handleChange}
                              className="form-control"
                              value={values.safe_travels}
                            />
                            <div className="text-danger">
                              {errors?.safe_travels}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    {/* Segunda sección global */}
                    <div>
                      <Row>
                        <Col>
                          {/* <hr
                            style={{
                              backgroundColor: "black",
                              height: "1px",
                            }}
                          /> */}
                          <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={6}>
                          <h2
                            style={{
                              fontWeight: "normal",
                              marginBottom: "15px",
                            }}
                          >
                            Redes sociales
                          </h2>
                        </Col>
                      </Row>
                      {/* Sección 1 */}
                      <Row>
                        <Col sm={12} md={6}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              Facebook
                            </label>
                            <br />
                            <label>URL</label>
                            <input
                              type="text"
                              name="facebook"
                              onChange={handleChange}
                              className="form-control"
                              value={values.facebook}
                            />
                            <div className="text-danger">
                              {errors?.facebook}
                            </div>
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              LinkedIn
                            </label>
                            <br />
                            <label>URL</label>
                            <input
                              type="text"
                              name="linkedin"
                              onChange={handleChange}
                              className="form-control"
                              value={values.linkedin}
                              // value={values.centralTurno}
                            />
                            <div className="text-danger">
                              {errors?.linkedin}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* Sección 2 */}

                      <Row>
                        <Col sm={12} md={6}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              Twitter
                            </label>
                            <br />
                            <label>URL</label>
                            <input
                              type="text"
                              name="twitter"
                              onChange={handleChange}
                              className="form-control"
                              value={values.twitter}
                            />
                            <div className="text-danger">{errors?.twitter}</div>
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              Instagram
                            </label>
                            <br />
                            <label>URL</label>
                            <input
                              type="text"
                              name="instagram"
                              onChange={handleChange}
                              className="form-control"
                              value={values.instagram}
                              // value={values.centralTurno}
                            />
                            <div className="text-danger">
                              {errors?.instagram}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    {/* Tercera sección */}

                    <div>
                      <Row>
                        <Col>
                        <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={6}>
                          <h2
                            style={{
                              fontWeight: "normal",
                              marginBottom: "15px",
                            }}
                          >
                            Domicilio legal
                          </h2>
                        </Col>
                      </Row>
                      {/* Sección 1 */}
                      <Row>
                        <Col sm={12} md={6}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              Dirección
                            </label>
                            <input
                              type="text"
                              name="direccion_domicilio"
                              onChange={handleChange}
                              className="form-control"
                              value={values.direccion_domicilio}
                            />
                            <div className="text-danger">
                              {errors?.direccion_domicilio}
                            </div>
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              Teléfono
                            </label>
                            <input
                              type="text"
                              name="telefono_domicilio"
                              onChange={handleChange}
                              className="form-control"
                              value={values.telefono_domicilio}
                              // value={values.centralTurno}
                            />
                            <div className="text-danger">
                              {errors?.telefono_domicilio}
                            </div>
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6}>
                            <Form.Group>
                              <label style={{ fontWeight: "normal" }}>
                                URL Google Maps
                              </label>
                              <input
                                type="text"
                                name="ubicacion"
                                onChange={handleChange}
                                className="form-control"
                                value={values.ubicacion}
                                // value={values.centralTurno}
                              />
                              <div className="text-danger">
                                {errors?.ubicacion}
                              </div>
                            </Form.Group>
                          </Col>
                      </Row>
                    </div>

                    {/* Cuarta sección */}

                    <div>
                      <Row>
                        <Col>
                        <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={6}>
                          <h2
                            style={{
                              fontWeight: "normal",
                              marginBottom: "15px",
                            }}
                          >
                            Legales - Popup
                          </h2>
                        </Col>
                      </Row>
                      {/* Sección 1 */}
                      <Row>
                        <Col sm={12}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              Políticas de privacidad
                            </label>
                            <br />
                            <label>Título</label>
                            <input
                              type="text"
                              name="titulo_politicas"
                              onChange={handleChange}
                              className="form-control"
                              value={values.titulo_politicas}
                            />
                            <div className="text-danger">
                              {errors?.titulo_politicas}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          className={`${layoutJodit == "MOBILE" && "col-6"}`}
                        >
                          <Form.Group>
                            <label>Contenido</label>
                            <LayoutJodit
                              setLayoutJodit={setLayoutJodit}
                              layoutJodit={layoutJodit}
                            />
                            <JoditEditor
                              config={config}
                              value={values.contenido_politicas}
                              tabIndex={1} // tabIndex of textarea
                              onBlur={(newContent) =>
                                handleChangeJoiEditor(
                                  newContent,
                                  "contenido_politicas",
                                  setFieldValue
                                )
                              }
                            />

                            <div className="text-danger">
                              {errors?.contenido_politicas}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              Términos y condiciones
                            </label>
                            <br />
                            <label>Título</label>
                            <input
                              type="text"
                              name="titulo_terminos"
                              onChange={handleChange}
                              className="form-control"
                              value={values.titulo_terminos}
                            />
                            <div className="text-danger">
                              {errors?.titulo_terminos}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          className={`${layoutJodit == "MOBILE" && "col-6"}`}
                        >
                          <Form.Group>
                            <label>Contenido</label>
                            <LayoutJodit
                              setLayoutJodit={setLayoutJodit}
                              layoutJodit={layoutJodit}
                            />
                            <JoditEditor
                              config={config}
                              value={values.contenido_terminos}
                              tabIndex={1} // tabIndex of textarea
                              onBlur={(newContent) =>
                                handleChangeJoiEditor(
                                  newContent,
                                  "contenido_terminos",
                                  setFieldValue
                                )
                              }
                            />

                            <div className="text-danger">
                              {errors?.contenido_terminos}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12}>
                          <Form.Group>
                            <label style={{ fontWeight: "normal" }}>
                              Intranet
                            </label>
                            <br />
                            <label>URL</label>
                            <input
                              type="text"
                              name="intranet"
                              onChange={handleChange}
                              className="form-control"
                              value={values.intranet}
                            />
                            <div className="text-danger">
                              {errors?.intranet}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    {/* Quinta sección */}

                    <div>
                      <Row>
                        <Col>                          
                        <div style={{borderBottom:"1px solid black",margin:"10px 0 15px 0"}}></div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={6}>
                          <h2
                            style={{
                              fontWeight: "normal",
                              marginBottom: "15px",
                            }}
                          >
                            Contrato de adhesión de los socios
                          </h2>
                        </Col>
                      </Row>
                      {/* Sección 1 */}
                      <Row>
                        <Col>
                          <Form.Group>
                            {/* <label>PDF</label> */}
                            {values?.contrato?.url!=null ?
                            <div className="preview-button">
                                <div className="preview-component">
                                <a className="btn btn-info" href={values?.contrato?.url} target="_blank">
                                    <i className="fa fa-eye " />                                
                                </a>
                              <span>Previsualizar archivo actual</span>
                              </div>
                              <span>Cargar el nuevo archivo que remplazará el existente</span>
                            </div>:
                            <div style={{marginTop:"10px"}}></div>
                            }                            
                            <MediaUploader
                              value={values?.contrato ? 100 : 0}
                              key={values?.contrato}
                              setFieldValue={(name, value) => {
                                setFieldValue(name, value, false);
                                // setValues({ ...values, [name]: value });
                              }}
                              field={"contrato"}
                              tipoMedia="archivo"
                            />
                            {errors && errors.contrato && (
                              <div className="text-danger">
                                {errors.contrato}
                              </div>
                            )}
                            {/* <span className="text-muted">
                              Se recomienda una imagen de 300px de ancho x 300px
                              de alto
                            </span> */}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Formatos permitidos: Archivo PDF.</label>
                        </Col>
                      </Row>
                    </div>

                    <Botones
                      handleSubmit={() => handleSubmit()}
                      values={values}
                      validateForm={validateForm}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
};
