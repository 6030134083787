import React from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { FieldArray, Formik, useFormik } from "formik";
import { Fetch, catchError } from "../../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertInfo, AlertSuccess } from "../../../../components/alert";
import { loadingAction } from "../../../../redux/actions";
import MediaUploader from "../../../../utils/media-uploader";
import { validateModel } from "../validationSchema";
import { useHistory } from "react-router-dom";
import { EstadoContenido } from "../../../../utils/constants";
import { eliminarImagen } from "../../../../utils/tools";
import BadgeUpdate from "../../../../utils/badgeUpdate";

const NuevoHomeSlide = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const idEmpresa = useSelector((state) => state.user.data.empresa.id);
  const userType = useSelector((state) => state.user.data.role.type);

  const submit = async (values) => {
    values.published_at = null;
    values.idEmpresa = idEmpresa;
    values.estado = EstadoContenido.EnRevision;

    try {
      dispatch(loadingAction(true));
      await Fetch("home-slides", "post", values);
      dispatch(loadingAction(false));
      BadgeUpdate(idEmpresa, dispatch);

      AlertInfo(
        "Banner creado con éxito.<br/>Recuerde que falta su confirmación para aprobar."
      );
      history.push("/configuracion/home-slide/lista-borradores");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const initialValues = {
    //tipo: "IMAGEN", //IMAGEN O VIDEO
    media: "",
    enlace: "#",
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Card>
                  <Card.Header>
                    <Row>
                      <Col>
                        <h3>Nuevo banner</h3>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Seleccionar archivo</label>
                          <MediaUploader
                            setFieldValue={setFieldValue}
                            field={"media"}
                            tipoMedia="imagen"
                            ancho={1600}
                            alto={580}
                            value={values?.media ? 100 : 0}
                            key={values?.media}
                          />
                          <span className="text-muted">
                            Se recomienda una imagen de 1600px de ancho x 580px
                            de alto. En formato JPG o PNG.
                          </span>
                          <div className="text-danger">{errors?.media}</div>
                          {Object.keys(values.media).length > 0 && (
                            <>
                              <Row>
                                <Col>
                                  <Form.Group>
                                    <img
                                      src={values.media.url}
                                      alt="vista-previa"
                                      className="img-fluid w-50"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Button
                                    type="button"
                                    style={{ marginBottom: "10px" }}
                                    onClick={() => {
                                      eliminarImagen(values?.media?.id).then(
                                        (response) => {
                                          setFieldValue("media", "", false);
                                        }
                                      );
                                    }}
                                    className="btn btn-danger btn-icon mt-2"
                                  >
                                    <i className="fa fa-trash-o " />
                                    Eliminar
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Enlace</label>
                          <input
                            type="text"
                            name="enlace"
                            onChange={handleChange}
                            className="form-control"
                            value={values.enlace}
                          />
                          <div className="text-danger">{errors?.enlace}</div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-success btn-icon mt-2"
                    >
                      <i className="fa fa-floppy-o " />
                      Guardar
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default NuevoHomeSlide;
