import { Formik } from "formik";
import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { validateModel } from "./validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AlertInfo } from "../../alert";
import { Fetch, catchError } from "../../../utils/fetch";
import { loadingAction } from "../../../redux/actions";
import { EstadoContenido } from "../../../utils/constants";
import BadgeUpdate from "../../../utils/badgeUpdate";

export const FormularioPaginaPrincipalComponent = ({ estado }) => {
  const initialValues = {
    atencionTelefonica: "",
    centralTurno: "",
    whatsapp: "",
    novedad: "",
    estadoNovedad: "",
    enlaceNovedad: "#",
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const idEmpresa = useSelector((state) => state.user.data.empresa.id);
  const userType = useSelector((state) => state.user.data.role.type);
  const [formValues, setFormValues] = React.useState({});
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions["pagina-principal"].publicar.enabled;

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        `pagina-principals?idEmpresa=${idEmpresa}&_publicationState=preview`,
        "get"
      );

      if (estado === EstadoContenido.Publicado) setFormValues(data[0]);
      else {
        let model = data[0];

        model.atencionTelefonica = model.atencionTelefonicaTmp;
        model.centralTurno = model.centralTurnoTmp;
        model.whatsapp = model.whatsappTmp;
        model.novedad = model.novedadTmp;
        model.estadoNovedad = model.estadoNovedadTmp;
        model.enlaceNovedad = model.enlaceNovedadTmp;

        setFormValues(model);
      }

      dispatch(loadingAction(false));
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const onActualizar = async (handleSubmit, values) => {
    handleSubmit();
    try {
      dispatch(loadingAction(true));

      const model = {
        atencionTelefonicaTmp: values.atencionTelefonica,
        centralTurnoTmp: values.centralTurno,
        whatsappTmp: values.whatsapp,
        novedadTmp: values.novedad,
        estadoNovedadTmp: values.estadoNovedad,
        enlaceNovedadTmp: values.enlaceNovedad,
        estado: EstadoContenido.EnRevision,
      };

      await Fetch("pagina-principals/" + values.id, "put", model);
      dispatch(loadingAction(false));
      BadgeUpdate(idEmpresa, dispatch);
      AlertInfo("Contenido actualizado con éxito.");
      history.push("/configuracion/pagina-principal/en-revision");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onEnviarAprobar = async (handleSubmit, values) => {
    handleSubmit();

    try {
      dispatch(loadingAction(true));

      const model = {
        atencionTelefonicaTmp: values.atencionTelefonica,
        centralTurnoTmp: values.centralTurno,
        whatsappTmp: values.whatsapp,
        novedadTmp: values.novedad,
        estadoNovedadTmp: values.estadoNovedad,
        enlaceNovedadTmp: values.enlaceNovedad,
        estado: EstadoContenido.EnAprobacion,
      };

      await Fetch("pagina-principals/" + values.id, "put", model);
      dispatch(loadingAction(false));
      BadgeUpdate(idEmpresa, dispatch);
      if (userType == "aprobador") {
        AlertInfo("Contenido enviado con éxito");
        history.push("/configuracion/pagina-principal/por-aprobar");
      } else {
        AlertInfo(
          "Contenido actualizado con éxito.<br/>Recuerde que falta la confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar."
        );
      }
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onPublicar = async (handleSubmit, values) => {
    handleSubmit();

    try {
      if (!canPublish) {
        AlertInfo("No tiene permiso para realizar esta acción");
        return;
      }

      values.atencionTelefonicaTmp = "";
      values.centralTurnoTmp = "";
      values.whatsappTmp = "";
      values.novedadTmp = "";
      values.estadoNovedadTmp = false;
      values.enlaceNovedadTmp = "";
      values.estado = EstadoContenido.Publicado;

      dispatch(loadingAction(true));
      BadgeUpdate(idEmpresa, dispatch);
      await Fetch("pagina-principals/" + values.id, "put", values);
      dispatch(loadingAction(false));

      AlertInfo("Contenido publicado con éxito");
      history.push("/configuracion/pagina-principal/publicado");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onEnviarARevision = async (handleSubmit, values) => {
    handleSubmit();

    try {
      if (!canPublish) {
        AlertInfo("No tiene permiso para realizar esta acción");
        return;
      }

      dispatch(loadingAction(true));

      values.estado = EstadoContenido.EnRevision;
      await Fetch("pagina-principals/" + values.id, "put", values);
      dispatch(loadingAction(false));
      BadgeUpdate(idEmpresa, dispatch);
      AlertInfo("Se ha cancelado la publicación de la sección con éxito");
      history.push("/configuracion/pagina-principal/en-revision");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const isDisabled = (val) => {
    const {
      atencionTelefonica,
      centralTurno,
      whatsapp,
      novedad,
      estadoNovedad,
    } = val;

    if (estadoNovedad === false || estadoNovedad === "false") {
      return (
        atencionTelefonica === "" || centralTurno === "" || whatsapp === ""
      );
    } else if (estadoNovedad === true || estadoNovedad === "true") {
      return (
        atencionTelefonica === "" ||
        centralTurno === "" ||
        whatsapp === "" ||
        novedad === ""
      );
    }

    return false;
  };

  function Botones({ handleSubmit, values }) {
    if (estado === EstadoContenido.Publicado) {
      return (
        <Row className="mt-5">
          <Col className="col col-lg-2">
            <Button
              type="button"
              onClick={() => onActualizar(handleSubmit, values)}
              disabled={isLoading}
              className="btn btn-success btn-icon"
            >
              <i className="fa fa-floppy-o " />
              Guardar
            </Button>
          </Col>
        </Row>
      );
    }

    if (estado === EstadoContenido.EnRevision) {
      let buttonDisabled = isDisabled(values);
      if (values.estado != "ER") {
        buttonDisabled = true;
      }

      return (
        <Row className="mt-5">
          <Col className="col col-lg-2">
            <Button
              type="button"
              onClick={() => onActualizar(handleSubmit, values)}
              disabled={buttonDisabled}
              // className="btn btn-success btn-icon"
              className={"btn btn-success btn-icon no-hover"}
            >
              <i className="fa fa-floppy-o " />
              Guardar
            </Button>
          </Col>
          <Col className="col col-lg-3">
            <Button
              type="button"
              onClick={() => onEnviarAprobar(handleSubmit, values)}
              disabled={buttonDisabled}
              className="btn btn-info btn-icon no-hover2"
            >
              <i className="fa fa-share " />
              Enviar aprobar
            </Button>
          </Col>
        </Row>
      );
    }

    if (estado === EstadoContenido.EnAprobacion) {
      let buttonDisabled = isDisabled(values);
      if (values.estado != "EA") {
        buttonDisabled = true;
      }

      return (
        <Row className="mt-5">
          <Col className="col col-lg-2">
            <Button
              type="button"
              onClick={() => onPublicar(handleSubmit, values)}
              disabled={buttonDisabled}
              className="btn btn-success btn-icon no-hover"
            >
              <i className="fa fa-floppy-o " />
              Publicar
            </Button>
          </Col>
          <Col className="col col-lg-3">
            <Button
              type="button"
              onClick={() => onEnviarARevision(handleSubmit, values)}
              disabled={buttonDisabled}
              className="btn btn-primary btn-icon no-hover3"
            >
              <i className="fa fa-undo " />
              Por revisar
            </Button>
          </Col>
        </Row>
      );
    }
  }

  //PARA EN REVISION
  if (estado === EstadoContenido.EnRevision) {
    return (
      <>
        <Formik
          initialValues={formValues}
          enableReinitialize
          validationSchema={validateModel}
          onSubmit={(values, { setSubmitting }) => {
            //submit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Card>
                    <Card.Header>
                      <Row>
                        <Col>
                          <h3>Página principal</h3>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Atención telefónica</label>
                            <input
                              type="text"
                              name="atencionTelefonica"
                              onChange={handleChange}
                              className="form-control"
                              value={
                                values.estado == "ER"
                                  ? values.atencionTelefonica
                                  : ""
                              }
                            />
                            <div className="text-danger">
                              {errors?.atencionTelefonica}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Central de turno</label>
                            <input
                              type="text"
                              name="centralTurno"
                              onChange={handleChange}
                              className="form-control"
                              value={
                                values.estado == "ER" ? values.centralTurno : ""
                              }
                              // value={values.centralTurno}
                            />
                            <div className="text-danger">
                              {errors?.centralTurno}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Whatsapp</label>
                            <input
                              type="text"
                              name="whatsapp"
                              onChange={handleChange}
                              className="form-control"
                              // value={values.whatsapp}
                              value={
                                values.estado == "ER" ? values.whatsapp : ""
                              }
                            />
                            <div className="text-danger">
                              {errors?.whatsapp}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Novedad</label>
                            <input
                              type="text"
                              name="novedad"
                              size={100}
                              onChange={handleChange}
                              className="form-control"
                              // value={values.novedad}
                              value={
                                values.estado == "ER" ? values.novedad : ""
                              }
                            />
                            <div className="text-danger">{errors?.novedad}</div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Enlace de la novedad</label>
                            <input
                              type="text"
                              name="enlaceNovedad"
                              onChange={handleChange}
                              className="form-control"
                              // value={values.enlaceNovedad}
                              value={
                                values.estado == "ER"
                                  ? values.enlaceNovedad
                                  : ""
                              }
                            />
                            <div className="text-danger">
                              {errors?.enlaceNovedad}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Estado de la novedad</label>
                            <select
                              name="estadoNovedad"
                              onChange={handleChange}
                              className="form-control"
                              // value={values.estadoNovedad}
                              value={
                                values.estado == "ER"
                                  ? values.estadoNovedad
                                  : false
                              }
                            >
                              <option value={true} selected>
                                Activo
                              </option>
                              <option value={false}>Inactivo</option>
                            </select>
                            <div className="text-danger">
                              {errors?.estadoNovedad}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Botones
                        handleSubmit={() => handleSubmit()}
                        values={values}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </>
    );
  }

  //PARA POR APROBAR
  if (estado === EstadoContenido.EnAprobacion) {
    return (
      <>
        <Formik
          initialValues={formValues}
          enableReinitialize
          validationSchema={validateModel}
          onSubmit={(values, { setSubmitting }) => {
            //submit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Card>
                    <Card.Header>
                      <Row>
                        <Col>
                          <h3>Página principal</h3>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Atención telefónica</label>
                            <input
                              type="text"
                              name="atencionTelefonica"
                              onChange={handleChange}
                              className="form-control"
                              value={
                                values.estado == "EA"
                                  ? values.atencionTelefonica
                                  : ""
                              }
                            />
                            <div className="text-danger">
                              {errors?.atencionTelefonica}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Central de turno</label>
                            <input
                              type="text"
                              name="centralTurno"
                              onChange={handleChange}
                              className="form-control"
                              // value={values.centralTurno}
                              value={
                                values.estado == "EA" ? values.centralTurno : ""
                              }
                            />
                            <div className="text-danger">
                              {errors?.centralTurno}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Whatsapp</label>
                            <input
                              type="text"
                              name="whatsapp"
                              onChange={handleChange}
                              className="form-control"
                              // value={values.whatsapp}
                              value={
                                values.estado == "EA" ? values.whatsapp : ""
                              }
                            />
                            <div className="text-danger">
                              {errors?.whatsapp}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Novedad</label>
                            <input
                              type="text"
                              name="novedad"
                              size={100}
                              onChange={handleChange}
                              className="form-control"
                              // value={values.novedad}
                              value={
                                values.estado == "EA" ? values.novedad : ""
                              }
                            />
                            <div className="text-danger">{errors?.novedad}</div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Enlace de la novedad</label>
                            <input
                              type="text"
                              name="enlaceNovedad"
                              onChange={handleChange}
                              className="form-control"
                              // value={values.enlaceNovedad}
                              value={
                                values.estado == "EA"
                                  ? values.enlaceNovedad
                                  : ""
                              }
                            />
                            <div className="text-danger">
                              {errors?.enlaceNovedad}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Estado de la novedad</label>
                            <select
                              name="estadoNovedad"
                              onChange={handleChange}
                              className="form-control"
                              // value={values.estadoNovedad}
                              value={
                                values.estado == "EA"
                                  ? values.estadoNovedad
                                  : false
                              }
                            >
                              <option value={true} selected>
                                Activo
                              </option>
                              <option value={false}>Inactivo</option>
                            </select>
                            <div className="text-danger">
                              {errors?.estadoNovedad}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Botones
                        handleSubmit={() => handleSubmit()}
                        values={values}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </>
    );
  }

  //PARA PUBLICADOS
  return (
    <>
      <Formik
        initialValues={formValues}
        enableReinitialize
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          //submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Card>
                  <Card.Header>
                    <Row>
                      <Col>
                        <h3>Página principal</h3>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Atención telefónica</label>
                          <input
                            type="text"
                            name="atencionTelefonica"
                            onChange={handleChange}
                            className="form-control"
                            value={values.atencionTelefonica}
                          />
                          <div className="text-danger">
                            {errors?.atencionTelefonica}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Central de turno</label>
                          <input
                            type="text"
                            name="centralTurno"
                            onChange={handleChange}
                            className="form-control"
                            value={values.centralTurno}
                          />
                          <div className="text-danger">
                            {errors?.centralTurno}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Whatsapp</label>
                          <input
                            type="text"
                            name="whatsapp"
                            onChange={handleChange}
                            className="form-control"
                            value={values.whatsapp}
                          />
                          <div className="text-danger">{errors?.whatsapp}</div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Novedad</label>
                          <input
                            type="text"
                            name="novedad"
                            size={100}
                            onChange={handleChange}
                            className="form-control"
                            value={values.novedad}
                          />
                          <div className="text-danger">{errors?.novedad}</div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Enlace de la novedad</label>
                          <input
                            type="text"
                            name="enlaceNovedad"
                            onChange={handleChange}
                            className="form-control"
                            value={values.enlaceNovedad}
                          />
                          <div className="text-danger">
                            {errors?.enlaceNovedad}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Estado de la novedad</label>
                          <select
                            name="estadoNovedad"
                            onChange={handleChange}
                            className="form-control"
                            value={values.estadoNovedad}
                          >
                            <option value={true} selected>
                              Activo
                            </option>
                            <option value={false}>Inactivo</option>
                          </select>
                          <div className="text-danger">
                            {errors?.estadoNovedad}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Botones
                      handleSubmit={() => handleSubmit()}
                      values={values}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
};
