import React, { useState } from "react";
import { Link } from "react-router-dom";
import BeNavChildren from "./nav-children";
import { Nav } from "react-bootstrap";
import "./nav.css";
import { useSelector } from "react-redux";

const BeNavItem = ({ nav, rootNav }) => {
  const idRol = useSelector((state) => state.user.data.role.id);
  const idEmpresa = useSelector((state) => state.user.data.empresa.id);
  const stats = useSelector((state) => state.badges);
  const [childClick, setChildClick] = useState(false);

  // Función para quitar "selected-link" de las clases de los elementos
  const removeSelectedLinkClass = (event, childStatus, uuid) => {
    const links = document.querySelectorAll(".selected-link");
    links.forEach((link) => {
      link.className = link.className.replace("selected-link", "").trim();
    });
    handleToggle(event, childStatus, uuid);
  };

  const handleToggle = (event, childStatus, uuid) => {
    //Hijo seleccionado
    let menu = document.getElementById(uuid);
    if (menu) {
      menu.classList.add("selected-link");
    }

    //Padre seleccionado
    let menuPadre = document.querySelector(`[menu-uuid="${uuid}"]`);
    // let links = document.querySelectorAll(uuid);
    if (menuPadre) {
      // menuPadre.classList.add("selected-link");

      let iconElement = menuPadre.querySelector("i");
      if (iconElement) {
        iconElement.classList.add("selected-link");
      }

      // Encuentra el elemento <span> dentro del elemento <a> y agrega la clase "selected-link"
      let spanElement = menuPadre.querySelector("span");
      if (spanElement) {
        spanElement.classList.add("selected-link");
      }
    }

    setChildClick(uuid);

    if (!childStatus) {
      return false;
    }

    const navItem = event.currentTarget.parentNode;
    const navChild = event.currentTarget.nextSibling;
    const menuNode = event.currentTarget.parentNode.parentNode.childNodes;

    for (let i = 0; i < menuNode.length; i++) {
      if (menuNode[i].firstChild.attributes.getNamedItem("menu-uuid") ?? false)
        if (
          menuNode[i].firstChild.attributes.getNamedItem("menu-uuid").value ===
          uuid
        )
          continue;

      if (menuNode[i].classList.contains("active")) {
        menuNode[i].lastChild.classList.remove("in");
        menuNode[i].classList.remove("active");
      }
    }

    if (navItem.classList.contains("active")) {
      navItem.classList.remove("active");
      navChild.classList.remove("in");
    } else {
      navItem.classList.add("active");
      navChild.classList.add("in");
    }

    event.preventDefault();
  };

  if (!nav) return null;

  const cargaMenuPadre = () => {
    if (idRol == 3 && idEmpresa == 1) {
      if (
        nav.name === "Página principal" &&
        (stats.paginaPrincipal.poraprobar || stats.paginaPrincipal.porrevisar)
      ) {
        return (
          <span className="toggle-none">
            <span class="position-absolute start-100 translate-middle badge rounded-pill bg-danger badgePadre"></span>
            {nav.name}
            <span className="fa arrow " />
          </span>
        );
      }
      if (
        nav.name === "Home banner" &&
        (stats.homeSlide.porrevisar || stats.homeSlide.poraprobar)
      ) {
        return (
          <span className="toggle-none">
            <span class="position-absolute start-100 translate-middle badge rounded-pill bg-danger badgePadre"></span>
            {nav.name}
            <span className="fa arrow " />
          </span>
        );
      }
    }
    return (
      <span className="toggle-none">
        {nav.name}
        <span className="fa arrow " />
      </span>
    );
  };

  const cargaMenuHijo = () => {
    if (idRol == 3 && idEmpresa == 1) {
      if (nav.uuid === "dumy-uuidRevi" && stats.homeSlide.porrevisar) {
        return (
          <div
            id={nav.uuid}
            style={{ display: "inline" }}
            className={childClick === nav.uuid ? "selected-link" : ""}
          >
            <span className="position-absolute start-100 translate-middle badge rounded-pill bg-danger badgeHijo">
              {/* {stats?.homeSlide?.porrevisar} */}
            </span>
            {nav.name}
          </div>
        );
      }
      if (nav.uuid === "dumy-uuidApro" && stats.homeSlide.poraprobar) {
        return (
          <div
            id={nav.uuid}
            style={{ display: "inline" }}
            className={childClick === nav.uuid ? "selected-link" : ""}
          >
            <span className="position-absolute start-100 translate-middle badge rounded-pill bg-danger badgeHijo">
              {/* {stats?.homeSlide?.poraprobar} */}
            </span>
            {nav.name}
          </div>
        );
      }
      if (
        nav.uuid === "dumy-uuidReviPrin" &&
        stats.paginaPrincipal.porrevisar
      ) {
        return (
          <div
            id={nav.uuid}
            style={{ display: "inline" }}
            className={childClick === nav.uuid ? "selected-link" : ""}
          >
            <span className="position-absolute start-100 translate-middle badge rounded-pill bg-danger badgeHijo">
              {/* {stats?.paginaPrincipal?.porrevisar} */}
            </span>
            {nav.name}
          </div>
        );
      }
      if (
        nav.uuid === "dumy-uuidAproPrin" &&
        stats.paginaPrincipal.poraprobar
      ) {
        return (
          <div
            id={nav.uuid}
            style={{ display: "inline" }}
            className={childClick === nav.uuid ? "selected-link" : ""}
          >
            <span className="position-absolute start-100 translate-middle badge rounded-pill bg-danger badgeHijo">
              {/* {stats?.paginaPrincipal?.poraprobar} */}
            </span>
            {nav.name}
          </div>
        );
      }
    }

    return (
      <div
        id={nav.uuid}
        style={{ display: "inline" }}
        className={childClick === nav.uuid ? "selected-link" : ""}
      >
        {nav.name}
      </div>
    );
  };

  return (
    <Nav.Item as="li">
      <Link
        className="nav-link"
        to={nav.to ? nav.to : "#be"}
        key={nav.uuid}
        menu-uuid={nav.uuid}
        onClick={(e) =>
          removeSelectedLinkClass(e, nav.children ? 1 : 0, nav.uuid)
        }
      >
        {(() => {
          if (nav.icon) {
            return (
              <i
                className={
                  nav.icon +
                  " " +
                  (childClick === nav.uuid ? "selected-link" : "")
                }
                style={{ paddingRight: "10px" }}
              ></i>
            );
          }
        })()}

        {(() => {
          if (nav.children) {
            // Menú padre con hijos
            return <>{cargaMenuPadre()}</>;
          } else if (rootNav) {
            // Menú solo sin hijos
            return (
              <span
                className={`toggle-none ${
                  childClick === nav.uuid ? "selected-link" : ""
                }`}
              >
                {nav.name}
              </span>
            );
          } else {
            // Hijos
            return <React.Fragment>{cargaMenuHijo()}</React.Fragment>;
          }
        })()}
      </Link>

      {(() => {
        if (nav.children) {
          return (
            <Nav
              as="ul"
              className="nav-second-level flex-column sub-menu collapse"
            >
              <BeNavChildren children={nav.children} />
            </Nav>
          );
        }
      })()}
    </Nav.Item>
  );
};

export default BeNavItem;
