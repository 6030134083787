import React from "react";
import './styles.scss';
import DataGrid, {
  LoadPanel,
  Column,
  FilterRow,
  Paging,
  SearchPanel,
  Export,
  RowDragging,
  Button,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
// import { Button } from 'react-bootstrap';
//import { onExportarExcel } from '../../utilities/Util'

export const DataGridForm = ({
  dataSource,
  allowColumnReordering = true,
  showBorders = true,
  rowAlternationEnabled = true,
  showRowLines = true,
  hoverStateEnabled = true,
  exportEnabled = false,
  exporting = "archivo",
  focusedRowEnabled = true,
  filterRow = true,
  searchPanel = false,
  defaultPageSize = 10,
  keyExpr,
  columns = [],
  onCellClick = () => null,
  onReorder,
  allowReordering = false,
  columnHidingEnabled = false,
  allowColumnResizing = true,
  remoteOperations = false,
  showButton = false,
}) => {
  const onExportarExcel = (e, exporting) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: function (options) {
        options.excelCell.font = { name: "Arial", size: 12 };
        options.excelCell.alignment = { horizontal: "left" };
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "LogDebito.xlsx"
        );
      });
    });
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items = e.toolbarOptions.items.filter(
      (item) => item.name !== "exportButton"
    );
    e.toolbarOptions.items.push({
      widget: "dxButton",
      options: {
        icon: () => (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 48 48">
            <path d="M42 4H18a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm-1 35H19V9h22v30zM7 9v30h5V9H7zm12.536 6h2.561l3.557 5.486L27.195 15h2.464l-4.689 7.174L30 30h-2.459l-3.808-5.998L19.9 30h-2.496l5.047-7.658L19.536 15z"/>
          </svg>
        ),
        text: "Exportar Excel",
        onClick: () => {
          e.component.exportToExcel(false);
        },
        elementAttr: {
          class: showButton?"custom-export-button": "custom-export-button-hidden",
        },
      },
      location: "after",
    });
  };

  return (
    <DataGrid
      dataSource={dataSource}
      onToolbarPreparing={onToolbarPreparing}
      allowColumnReordering={allowColumnReordering}
      showBorders={showBorders}
      rowAlternationEnabled={rowAlternationEnabled}
      showRowLines={showRowLines}
      hoverStateEnabled={hoverStateEnabled}
      onExporting={(e) => onExportarExcel(e, exporting)}
      focusedRowEnabled={focusedRowEnabled}
      columnHidingEnabled={false}
      allowColumnResizing={allowColumnResizing}
      keyExpr={keyExpr}
      onCellClick={(cell) => onCellClick(cell)}
      remoteOperations={remoteOperations}
    >
              
        <style jsx>{`.custom-export-button{} .custom-export-button:hover{}`}</style>

      <RowDragging
        allowReordering={allowReordering}
        onReorder={onReorder}
        //showDragIcons={this.state.showDragIcons}
      />
      <LoadPanel enabled />
      <FilterRow visible={filterRow} />
      <SearchPanel visible={searchPanel} highlightCaseSensitive={true} />
      <Paging defaultPageSize={defaultPageSize} />
      <Export enabled={exportEnabled} />

      {columns.map((c, i) => {
        if (c.view != undefined)
          return (
            <Column type="buttons" width={110} key={i.toString()}>
              <Button
                hint="Clone"
                cssClass={"btn btn-primary"}
                onClick={(e) => c.onTap(e)}
                children={<span>VER</span>}
              />
            </Column>
          );

        if (c.edit !== undefined)
          return (
            <Column type="buttons" width={110} key={i.toString()}>
              <Button
                hint="Clone"
                cssClass={"btn btn-primary"}
                onClick={(e) => c.onTap(e)}
                children={<span>EDITAR</span>}
              />
            </Column>
          );

        if (c.dataField !== undefined)
          return (
            <Column
              key={i.toString()}
              dataField={c.dataField}
              caption={c.caption}
              alignment={c.alignment}
              width={c.caption == "Id" ? 50 : c.width}
              allowSorting={c.allowSorting}
              hidingPriority={c.hidingPriority}
            />
          );

        if (c.dataField === undefined)
          return (
            <Column
              key={i.toString()}
              caption={c.caption}
              alignment={c.alignment}
              width={c.caption == "Id" ? 50 : c.width}
              allowSorting={c.allowSorting}
              cellRender={(item) => c.cellRender[c.render](item.data)}
              hidingPriority={c.hidingPriority}
            />
          );
      })}
    </DataGrid>
  );
};

export default DataGridForm;
