/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DataGridForm from "../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../redux/actions";
import { catchError, Fetch, FetchApi } from "../../../../utils/fetch";
import { formatDate } from "../../../../utils/formatDate";
import "./administradores.scss";
import CustomStore from "devextreme/data/custom_store";
import Swal from "sweetalert2";
import { Tooltip as ReactTooltip } from "react-tooltip";

function ListaUsuariosCMS({ isLoadingAction, isLoading }) {
  const [state, setState] = React.useState({
    filter: "",
    random: Math.random(),
  });
  const [contenido, setContenido] = React.useState(null);

  React.useEffect(() => {
    getContenido();
    // setContenido(
    //   new CustomStore({
    //     key: "idCustomer",
    //     load(loadOptions) {
    //       const resultado = Fetch(
    //         `customuser`,
    //         "get",
    //         {
    //           pageIndex: loadOptions.skip / loadOptions.take + 1,
    //           pageSize: loadOptions.take,
    //           filter: state.filter,
    //           random: state.random,
    //           filterColumns: loadOptions.filter,
    //           sort: loadOptions.sort,
    //         }
    //       ).then((response) => ({
    //         data: response?.data?.usuarios ?? [],
    //         totalCount: response?.data?.total ?? 0,
    //       }));

    //       return resultado;
    //     },
    //   })
    // );
  }, [state.random]);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch(`customuser`, "get");
      setContenido(data);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  const eliminarUsuario = (id) => {
    Swal.fire({
      title: "¡Atención!",
      html: "Estás por eliminar a este usuario, no será posible recuperarlo <br/> y ya no tendrá acceso al CMS.<br/> ¿Querés continuar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if(!result.isConfirmed)
      {
        return
      }
      Fetch(`customuser/delete/${id}`, "delete")
        .then((response) => {
          if (!response.data.error) {
            Swal.fire({
              title: "¡Éxito!",
              html: "Usuario eliminado exitosamente.",
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Ok",
            });
            getContenido()
          } else {
            Swal.fire({
              title: "¡Error!",
              html: response.data.message,
              icon: "error",
              showCancelButton: false,
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "¡Error!",
            html: "No se pudo eliminar el usuario, por favor intenta más tarde",
            icon: "error",
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
        });
    });
  };

  const blanquearUsuario = (id) => {
    Swal.fire({
      title: "¡Atención!",
      html: "Estás por blanquear la contraseña de este usuario,<br/> la próxima vez que ingrese deberá crear una nueva.<br/> ¿Querés continuar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if(!result.isConfirmed)
      {
        return
      }
      isLoadingAction(true);
      Fetch(`customuser/blanquear/${id}`, "post")
      .then((response) => {
        isLoadingAction(false);
        if (!response.data.error) {
          Swal.fire({
            title: "¡Éxito!",
            html: "Contraseña blanqueada exitosamente.",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
          getContenido()
        } else {          
          Swal.fire({
            title: "¡Error!",
            html: response.data.message,
            icon: "error",
            showCancelButton: false,
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((error) => {
        isLoadingAction(false);
        Swal.fire({
          title: "¡Error!",
          html: "No se pudo blanquear la contraseña",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "Ok",
        });
      });
    });
  };

  function Accion(data) {
    return (
      <>
        <Link
          to={`/usuarios/cms/editar-usuario/${data.idCustomer}`}
          className="btn btn-sm btn btn-success"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Editar"
        >          
            <ReactTooltip id="my-tooltip" />
            <i className="fa fa-pencil" />          
        </Link>{" "}

        <button
          className="btn btn-sm btn btn-purple"
          onClick={()=>blanquearUsuario(data.id)}
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Blanquear clave"
        >
          <ReactTooltip id="my-tooltip" />
          <i className="fa fa-lock" />
        </button>{" "}

        <button className="btn btn-sm btn btn-danger" onClick={()=>eliminarUsuario(data.id)}
        data-tooltip-id="my-tooltip"
        data-tooltip-content="Eliminar usuario"
        >
          <ReactTooltip id="my-tooltip" />
          <i className="fa fa-trash" />
        </button>{" "}
      </>
    );
  }

  const onBuscar = () => {
    setState({ ...state, random: Math.random() });
  };

  function renderCreadoPor(row) {
    if (!row.creado_por || Object.keys(row?.creado_por).length===0) return "STRAPI";

    return row?.creado_por?.nombre + " " + row?.creado_por?.apellido;
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>Usuarios</Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <DataGridForm
                  dataSource={contenido}
                  keyExpr="id"
                  // remoteOperations={true}
                  columns={[
                    { caption: "Apellido", dataField: "apellido" },
                    { caption: "Nombre", dataField: "nombre" },
                    { caption: "Mail", dataField: "email" },
                    { caption: "Rol", dataField: "role.name" },
                    {
                      caption: "Creado por",
                      render: "renderCreadoPor",
                      cellRender: { renderCreadoPor },
                    },
                    {
                      caption: "Acciones",
                      alignment: "center",
                      render: "Accion",
                      cellRender: { Accion },                      
                    },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaUsuariosCMS);
