/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from "formik";
import React from "react";
import { Card, Col, Row, Button, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DataGridForm from "../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../redux/actions";
import { catchError, Fetch, FetchApi } from "../../../../utils/fetch";
import { formatDate } from "../../../../utils/formatDate";
import "./logs-estudio.scss";
import { validateModel } from "./validationSchema";
import MonthPickerInput from "react-month-picker-input";

function LogsUsuariosCMS({ isLoadingAction, isLoading }) {
  const currentDate = new Date();
  const defaultMonthYear = `${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/01/${currentDate.getFullYear()}`;

  const [state, setState] = React.useState({
    filter: "",
    random: Math.random(),
    monthYear: defaultMonthYear,
  });
  const [contenido, setContenido] = React.useState(null);
  const [fullContenido, setFullContenido] = React.useState();
  const [topData, setTopData] = React.useState(null);

  const roles = {
    4: "Creador",
    3: "Aprobador",
    6: "Administrador",
    7: "SuperAdministrador",
  };

  React.useEffect(() => {
    getContenido();
  }, [state.random]);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch(
        `logs-usuarios?_limit=-1&_sort=created_at:desc`,
        "get"
      );
      setContenido(data);
      setFullContenido(data);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function role(data) {
    return roles[data.role];
  }

  function superadministrador(data) {
    return (
      data.superadministrador.nombre + " " + data.superadministrador.apellido
    );
  }

  function fecha(data) {
    return data.created_at.substring(0, 10);
  }

  function filterObject(obj) {
    return Object.values(obj).some((value) => {
      if (typeof value === "object" && value !== null) {
        return filterObject(value);
      } else if (typeof value === "string") {
        return value.toLowerCase().includes(state.filter.toLowerCase());
      }
      return false;
    });
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          {/* CARD DE BUSQUEDAD */}
          <Card>
            <Card.Header>Log usuarios CMS</Card.Header>
            <Card.Body>
              <Formik
                initialValues={state}
                enableReinitialize
                validationSchema={validateModel}
                onSubmit={(values, { setSubmitting }) => {
                  let filtered = fullContenido.filter((item) =>
                    filterObject(item)
                  );
                  const filterDate = new Date(state.monthYear);
                  filtered = filtered.filter((objeto) => {
                    const objetoDate = new Date(objeto.created_at);
                    return (
                      objetoDate.getMonth() === filterDate.getMonth() &&
                      objetoDate.getFullYear() === filterDate.getFullYear()
                    );
                  });
                  setContenido(filtered);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row className="mb-15">
                      <Col md="2">
                        <span>Mes</span>
                        <MonthPickerInput
                          lang="es"
                          year={new Date().getFullYear()}
                          month={new Date().getMonth()}
                          onChange={function (
                            maskedValue,
                            selectedYear,
                            selectedMonth
                          ) {
                            setState({
                              ...state,
                              monthYear:
                                (selectedMonth + 1)
                                  .toString()
                                  .padStart(2, "00") +
                                "/" +
                                "01/" +
                                selectedYear,
                            });
                          }}
                        />
                      </Col>
                      <Col md="4">
                        <span>Descripción</span>
                        <input
                          type="text"
                          className="form-control"
                          name="filter"
                          onChange={(e) => {
                            handleChange(e);
                            setState({
                              ...state,
                              filter: e.target.value, // Set the desired value here
                            });
                          }}
                          value={values?.filter}
                        />
                        <div className="text-danger">{errors?.filter}</div>
                      </Col>
                      <Col md="2" className="d-flex flex-column">
                        <span>&nbsp;</span>
                        <Button onClick={handleSubmit}>
                          <i className="fa fa-search mr-2"></i>Buscar
                        </Button>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>

              <Row>
                <Col md="12">
                  <div className="table-responsive">
                    <DataGridForm
                      dataSource={contenido}
                      keyExpr="id"
                      columns={[
                        { caption: "Apellido", dataField: "apellido" },
                        { caption: "Nombre", dataField: "nombre" },
                        {
                          caption: "Mail",
                          dataField: "email",
                        },
                        {
                          caption: "Rol",
                          render: "role",
                          cellRender: { role },
                        },
                        {
                          caption: "SuperAdministrador",
                          render: "superadministrador",
                          cellRender: { superadministrador },
                        },
                        { caption: "Descripcion", dataField: "descripcion" },
                        {
                          caption: "Fecha",
                          render: "fecha",
                          cellRender: { fecha },
                        },
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsUsuariosCMS);
