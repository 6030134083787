import React from 'react';
import { Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { FetchApi } from '../../../../utils/fetch'
import { connect } from 'react-redux'
import { loadingAction } from '../../../../redux/actions';
import DataGridForm from '../../../../components/common/DataGridForm';
import CustomStore from "devextreme/data/custom_store";
import "./logs-reintegros.scss";


function LogsReintegros({ isLoadingAction }) {

    const [contenido, setContenido] = React.useState(null);
    const [contenidoReintegros, setContenidoReintegros] = React.useState(null);
    const date = new Date();
    const fdate = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0");
    date.setMonth(date.getMonth() - 6);
    const idate = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0");

    const [state, setState] = React.useState({
        visibleModal: false,
        filter: "",
        random: Math.random(),
        idate: idate,
        fdate: fdate,
        customer: null
    })

    React.useEffect(() => {
        const nuevoContenido = new CustomStore({
            key: "idCustomer",
            load(loadOptions) {
                const resultado = FetchApi(
                    `customers/Api/v1/User/GetCustomersPSHB`,
                    "post",
                    {
                        pageIndex: loadOptions.skip / loadOptions.take + 1 || 1,
                        pageSize: loadOptions.take != undefined ? loadOptions.take : 10,
                        filter: state.filter,
                        random: state.random,
                        filterColumns: loadOptions.filter,
                        sort: loadOptions.sort != undefined ? loadOptions.sort : [{ "selector": "socialSecurityNumber", "desc": false }],
                    }
                ).then((response) => ({
                    data: response?.data?.data?.CustomerGroup ?? [],
                    totalCount: response?.data?.data?.TotalCount ?? 0,
                }));

                return resultado;
            },
        });

        setContenido(nuevoContenido);
    }, [state.random]);

    React.useEffect(() => {
        if (state.customer != null) {
            const nuevoContenido = new CustomStore({
                key: "rein",
                load(loadOptions) {
                    const resultado = FetchApi(
                        `psafiliates/api/v1/getrefoundstatusold?contra=${state.customer.socialSecurityNumber.toString().split(" ")[0]}&fecha_desde=${state.idate.replaceAll("-", "")}&fecha_hasta=${state.fdate.replaceAll("-", "")}`,
                        "get",
                        {
                            pageIndex: loadOptions.skip / loadOptions.take + 1 || 1,
                            pageSize: loadOptions.take != undefined ? loadOptions.take : 10,
                            filter: state.filter,
                            random: state.random,
                            filterColumns: loadOptions.filter,
                            sort: loadOptions.sort != undefined ? loadOptions.sort : [{ "selector": "alta_fecha", "desc": true }],
                        }
                    ).then((response) => ({
                        data: response?.data?.data?.ds?.map(val => { return { ...val, alta_fecha: val.alta_fecha ? val.alta_fecha.toString().slice(0, 10) : "", monto_aprobado: val.monto_aprobado ? convertCurrency(val.monto_aprobado) : "", presen_total: val.presen_total ? convertCurrency(val.presen_total) : "" } }) ?? [],
                        totalCount: response?.data?.data?.ds?.length ?? 0,
                    }));
                    return resultado;
                },
            });

            setContenidoReintegros(nuevoContenido);
        }
    }, [state.randomrein]);


    const onChange = async (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const onSearchPanelChange = (event) => {
        console.log(event)
    };

    const onBuscar = () => {
        setState({ ...state, random: Math.random() });
    };


    function handleKeyDown(event) {
        if (event.keyCode === 13) {
            onBuscar();
        }
    }

    function handleKeyDownRein(event) {
        if (event.keyCode === 13) {
            onBuscarReintegros();
        }
    }

    function convertCurrency(mount) {
        return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(mount)
    }
    const onBuscarReintegros = () => {
        setState({ ...state, randomrein: Math.random() });
    };

    const verReintegros = (e) => {
        const data = e.row.data;
        setState({ ...state, visibleModal: true, customer: data, randomrein: Math.random() });
    };

    const verDetalleReintegro = (e) => {
        const data = e.row.data;
        console.log(data)
        setState({ ...state, visibleModal: true });
    };

    const formatDate = (date = new Date) => {
        return `$`;
    }

    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            Miembros PSHB
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-15">
                                <Col md="3">
                                    <input
                                        placeholder="Buscar afiliado"
                                        type="text"
                                        name="filter"
                                        onChange={onChange}
                                        onKeyDown={handleKeyDown}
                                        value={state.filter}
                                        class="form-control"
                                    />
                                </Col>
                                <Col md="2">
                                    <Button onClick={onBuscar}>
                                        <i className="fa fa-search mr-2"></i>Filtrar
                                    </Button>
                                </Col>
                            </Row>

                            <div className="table-responsive">
                                <DataGridForm
                                    dataSource={contenido}
                                    remoteOperations={true}
                                    columns={[
                                        { caption: 'Credencial', dataField: 'socialSecurityNumber' },
                                        { caption: "Apellido", dataField: "lastName" },
                                        { caption: "Nombre", dataField: "firstName" },
                                        { caption: "Email", dataField: "email" },
                                        { caption: "DNI", dataField: "dni" },
                                        { view: true, onTap: verReintegros }
                                    ]} />
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal dialogClassName='rein-modal' show={state.visibleModal} onHide={() => setState({ ...state, visibleModal: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Reintegros de {state.customer != null ? state.customer.firstName : ''} {state.customer != null ? state.customer.lastName : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md="12">
                            <Card>
                                <Card.Header>
                                    Reintegros cargados
                                </Card.Header>
                                <Card.Body>
                                    <Row className="mb-15">
                                        <Col md="3">
                                            <input
                                                placeholder="Fecha Inicial"
                                                type="date"
                                                name="idate"
                                                onChange={onChange}
                                                onKeyDown={handleKeyDownRein}
                                                value={state.idate}
                                                class="form-control"
                                            />
                                        </Col>
                                        <Col md="3">
                                            <input
                                                placeholder="Fecha Final"
                                                type="date"
                                                name="fdate"
                                                onChange={onChange}
                                                onKeyDown={handleKeyDownRein}
                                                value={state.fdate}
                                                class="form-control"
                                            />
                                        </Col>
                                        <Col md="2">
                                            <Button onClick={onBuscarReintegros}>
                                                <i className="fa fa-search mr-2"></i>Filtrar
                                            </Button>
                                        </Col>
                                    </Row>

                                    <div className="table-responsive">
                                        <DataGridForm
                                            dataSource={contenidoReintegros}
                                            filterRow={false}
                                            columns={[
                                                { caption: '# Reintegro', dataField: 'rein' },
                                                { caption: "Fecha", dataField: "alta_fecha" },
                                                { caption: "Monto Presentado", dataField: "presen_total" },
                                                { caption: "Monto Recibido", dataField: "monto_aprobado" },
                                                { caption: "Estado", dataField: "estado" },
                                                // { view: true, onTap: verDetalleReintegro }
                                            ]} />
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setState({ ...state, visibleModal: false })}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsReintegros);