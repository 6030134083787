/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DataGridForm from "../../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../../redux/actions";
import { catchError, Fetch, FetchApi } from "../../../../../utils/fetch";
import { formatDate } from "../../../../../utils/formatDate";
import "./administradores.scss";
import CustomStore from "devextreme/data/custom_store";

function ListaPacientes({ isLoadingAction, isLoading }) {
  const [state, setState] = React.useState({
    filter: "",
    random: Math.random(),
  });
  const [contenido, setContenido] = React.useState(null);

  React.useEffect(() => {
    //getContenido()
    setContenido(
      new CustomStore({
        key: "idCustomer",
        load(loadOptions) {
          const resultado = FetchApi(
            `customers/Api/v1/User/GetAdminsFilter`,
            "post",
            {
              pageIndex: loadOptions.skip / loadOptions.take + 1,
              pageSize: loadOptions.take,
              filter: state.filter,
              random: state.random,
              filterColumns: loadOptions.filter,
              sort: loadOptions.sort,
            }
          ).then((response) => ({
            data: response?.data?.data?.CustomerGroup ?? [],
            totalCount: response?.data?.data?.TotalCount ?? 0,
          }));

          return resultado;
        },
      })
    );
  }, [state.random]);

  function FActualizacion(data) {
    return formatDate(data?.fecha_nacimiento);
  }

  function Accion(data) {
    return (
      <>
        <Link
          to={"/configuracion/botones-home/ver-borrador/" + data.id}
          className="btn btn-sm btn btn-success"
        >
          <i className="fa fa-pencil" />
        </Link>{" "}
      </>
    );
  }

  const onChange = async (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onBuscar = () => {
    setState({ ...state, random: Math.random() });
  };

  function Genero(data) {
    return (
      <>
        <span>{data.sex === "1" ? "Hombre" : "Mujer"}</span>
      </>
    );
  }

  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      onBuscar();
    }
  }
  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>Administradores y operadores del sistema</Card.Header>
            <Card.Body>
              <Row className="mb-15">
                <Col md="3">
                  <input
                    placeholder="Buscar administrador"
                    type="text"
                    name="filter"
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    value={state.filter}
                    class="form-control"
                  />
                </Col>
                <Col md="2">
                  <Button onClick={onBuscar}>
                    <i className="fa fa-search mr-2"></i>Filtrar
                  </Button>
                </Col>
              </Row>

              <div className="table-responsive">
                <DataGridForm
                  dataSource={contenido}
                  remoteOperations={true}
                  columns={[
                    { caption: "Apellido", dataField: "lastName" },
                    { caption: "Nombre", dataField: "firstName" },
                    { caption: "Email", dataField: "email" },
                    { caption: "Fecha de nacimiento", dataField: "birthday" },
                    { caption: "DNI", dataField: "dni" },
                    {
                      caption: "Género",
                      alignment: "left",
                      render: "Genero",
                      cellRender: { Genero },
                    },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPacientes);
