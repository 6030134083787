/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Fetch, catchError } from "../../../../utils/fetch";
import { connect, useSelector } from "react-redux";
import { loadingAction } from "../../../../redux/actions";
import DataGridForm from "../../../../components/common/DataGridForm";
import { Tooltip as ReactTooltip } from "react-tooltip";

function Ayudas({ isLoadingAction, isLoading }) {
  const [contenido, setContenido] = React.useState(null);
  const idEmpresa = useSelector((state) => state.user.data.empresa.id);

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch(`ayudas?idEmpresa=${idEmpresa}`, "get");
      setContenido(data);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function Accion(data) {
    let url = process.env.REACT_APP_BASE_URL_HOME;
    let pshb =
      "eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ";

    switch (data.codigo) {
      case "agregarFamiliarHB":
        url += "/grupo-familiar?open=1";
        break;
      case "preguntasFrecuentesHB":
        url += "/ayuda";
        break;
      case "agregarFamiliarPS":
        url += `/grupo-familiar?pshb=${pshb}&open=1`;
        break;
      case "preguntasFrecuentesPS":
        url += `/ayuda?pshb=${pshb}`;
        break;
      case "credencialDigitalPS":
        url += `/credencial-digital?pshb=${pshb}&open=1`;
        break;
      case "facturacionPS":
        url += `/facturas?pshb=${pshb}&open=1`;
        break;
      case "reintegrosPS":
        url += `/reintegro-estado?pshb=${pshb}&open=1`;
        break;
    }

    return (
      <>
        <a data-tooltip-id="my-tooltip" data-tooltip-content="Ver">
          <ReactTooltip id="my-tooltip" />
          <a href={url} target="_blank" className="btn btn-sm btn btn-info">
            <i className="fa fa-eye" />
          </a>{" "}
        </a>
        <a data-tooltip-id="my-tooltip" data-tooltip-content="Editar">
          <ReactTooltip id="my-tooltip" />
          <Link
            to={`/configuracion/ayuda/editar/${data.id}/${data.codigo}`}
            className="btn btn-sm btn btn-success"
          >
            <i className="fa fa-pencil" />
          </Link>{" "}
        </a>
      </>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>Ayudas</Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <DataGridForm
                  dataSource={contenido}
                  keyExpr="id"
                  columnHidingEnabled={true}
                  columns={[
                    { caption: "Ayuda", dataField: "titulo" },
                    {
                      caption: "Acción",
                      alignment: "center",
                      render: "Accion",
                      cellRender: { Accion },
                      hidingPriority: 0,
                    },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ayudas);
