/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DataGridForm from "../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../redux/actions";
import { catchError, Fetch, FetchApi } from "../../../../utils/fetch";
import { formatDate } from "../../../../utils/formatDate";
import "./laboratorio.scss";
import PieChart, {
  Series,
  Label,
  Connector,
  Size,
  Export,
} from "devextreme-react/pie-chart";

function Laboratorio({ isLoadingAction, isLoading }) {
  const [contenido, setContenido] = React.useState(null);

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await FetchApi(
        `patient/Api/v1/Costumers/GetStatisticsLaboratory`,
        "get",
        null
      );
      //******** Se agrega el for para editar la fecha, quitar la "T" antes de la hora */
      for (let i = 0; i < data.data.statistics.length; i++) {
        const fecha = data.data.statistics[i].fecha;
        const nuevaFecha = fecha.replace("T", " ");
        data.data.statistics[i].fecha = nuevaFecha;
      }
      setContenido(data?.data);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              {/* <Row>
                            <Col md="4">
                                <div className='fila'>
                                    <span>Cantidad de registros</span>
                                    <select class="form-control">
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                      <option value="150">150</option>
                                    </select>
                                </div>  
                            </Col>                              
                        </Row> */}
            </Card.Header>
            <Card.Body>
              <PieChart
                id="pie"
                dataSource={contenido?.pie}
                palette="Bright"
                title="BAJADA INFORMES DE LABORATORIO"
              >
                <Series argumentField="nombre" valueField="porcentaje">
                  <Label visible={true}>
                    <Connector visible={true} width={1} />
                  </Label>
                </Series>
                <Export enabled={true} />
              </PieChart>
              <div className="table-responsive">
                <DataGridForm
                  dataSource={contenido?.statistics}
                  keyExpr="indice"
                  exportEnabled={true}
                  columns={[
                    { caption: "Fecha y Hora", dataField: "fecha" },
                    { caption: "Paciente", dataField: "paciente" },
                    { caption: "Descripción", dataField: "descripcion" },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Laboratorio);
