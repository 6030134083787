/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row, Button, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DataGridForm from "../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../redux/actions";
import { catchError, Fetch, FetchApi } from "../../../../utils/fetch";
import { formatDate } from "../../../../utils/formatDate";
import "./logs-facturacion.scss";

function LogsFacturacion({ isLoadingAction, isLoading }) {
  const [contenido, setContenido] = React.useState(null);
  const [valor, setValor] = React.useState(0);

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    isLoadingAction(true);

    try {
      const { data } = await FetchApi(
        `patient/Api/v1/LogData/GetLogBilling`,
        "get",
        null
      );
      setValor(data?.data?.billing?.periods[0]?.quanty);
      setContenido(data?.data?.billing);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error en pacientes", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  const onChangePeriod = (e) => {
    const periodo = contenido.periods.find((x) => x.period === e.target.value);
    setValor(periodo.quanty);
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>Facturación</Card.Header>
            <Card.Body>
              <Row className="">
                <Col md="5">
                  <div className="fila">
                    <span>Elegir un periodo de facturación Mes/Año</span>
                    <select
                      class="form-control"
                      onChange={(v) => onChangePeriod(v)}
                    >
                      {contenido?.periods.map((item) => (
                        <option value={item.period}>{item.period}</option>
                      ))}
                    </select>
                  </div>
                  <span>
                    Total de mails enviados en el periodo seleccionado: {valor}
                  </span>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/* CARD DE ULTIMO PERIODO */}
          <Card>
            <Card.Header>
              Último periodo facturado enviado {contenido?.lastPeriodBilling}
            </Card.Header>
            <Card.Body></Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsFacturacion);
