import * as Yup from 'yup';

export const validateModel = Yup.object().shape({

    titulo: Yup.string().required('Campo obligatorio'),
    // requeridoDescripcionCorta: Yup.bool().required('Campo obligatorio'),
    descripcionCorta: Yup.string().nullable().when("requeridoDescripcionCorta", {
        is: true,
        then: Yup.string().required("Campo obligatorio")
    }),
    //tipoMultimedia: Yup.string().transform(v => (v === null ? 'I' : v)),
    // requeridoMultimedia: Yup.bool().required('Campo obligatorio'),
    multimedia: Yup.object().nullable().when("requeridoMultimedia", {
        is: true,
        then: Yup.object().nullable().required('Campo obligatorio')
    }),
    preguntas: Yup.array(
        Yup.object().shape({
            titulo: Yup.string().required('Campo obligatorio'),            
            requeridoPreguntaDescripcionCorta: Yup.bool().notRequired(),
            descripcionCorta: Yup.string().when("requeridoPreguntaDescripcionCorta", {
                is: true,
                then: Yup.string().required("Campo obligatorio"),                
            }),
            respuestas: Yup.array(
                Yup.object().shape({
                    descripcion: Yup.string().required('Campo obligatorio'),
                    // requeridoRespuestaMultimedia: Yup.bool().notRequired(),
                    // multimedia: Yup.object().nullable().when("requeridoRespuestaMultimedia", {
                    //     is: true,
                    //     then: Yup.object().nullable().required("Campo obligatorio")
                    // }),
                })
            ),
        })
    ),
});