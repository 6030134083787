/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataGridForm from "../../../../components/common/DataGridForm";
import { loadingAction } from "../../../../redux/actions";
import { catchError, Fetch } from "../../../../utils/fetch";
import { formatDate } from "../../../../utils/formatDate";
import { Tooltip as ReactTooltip } from "react-tooltip";

function ListarPorEliminarHomeSlides({ isLoadingAction, isLoading }) {
  const [contenido, setContenido] = React.useState(null);
  const idEmpresa = useSelector((state) => state.user.data.empresa.id);

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch(
        `home-slides?_eliminado=true&idEmpresa=${idEmpresa}`,
        "get"
      );
      setContenido(data);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function Imagen(data) {
    return data?.media?.formats?.thumbnail?.url ? (
      <>
        <img
          src={data?.media?.formats?.thumbnail?.url}
          style={{
            width: "64px",
            height: "64px",
            borderRadius: "32px",
            objectFit: "cover",
          }}
          className=""
          alt="images"
        />
      </>
    ) : null;
  }

  function FActualizacion(data) {
    return formatDate(data?.updated_at);
  }

  function Accion(data) {
    return (
      <>
        <a data-tooltip-id="my-tooltip" data-tooltip-content="Editar">
          <ReactTooltip id="my-tooltip" />
          <Link
            to={"/configuracion/home-slide/ver-para-eliminar/" + data.id}
            className="btn btn-sm btn btn-success"
          >
            <i className="fa fa-pencil" />
          </Link>{" "}
        </a>
        <a data-tooltip-id="my-tooltip" data-tooltip-content="Ver">
          <ReactTooltip id="my-tooltip" />
          <a
            href={`${data?.media?.url}`}
            target="_blank"
            className="btn btn-sm btn btn-info"
          >
            <i className="fa fa-eye" />
          </a>{" "}
        </a>
      </>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>Lista de banners por eliminar</Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <DataGridForm
                  dataSource={contenido}
                  keyExpr="id"
                  columns={[
                    { caption: "Id", dataField: "id" },
                    {
                      caption: "Imagen",
                      alignment: "center",
                      render: "Imagen",
                      cellRender: { Imagen },
                    },
                    { caption: "Creado por", dataField: "creado_por.username" },
                    {
                      caption: "Actualizado por",
                      dataField: "actualizado_por.username",
                    },
                    {
                      caption: "Ult. Actualización",
                      render: "FActualizacion",
                      cellRender: { FActualizacion },
                    },
                    {
                      caption: "Acción",
                      alignment: "center",
                      render: "Accion",
                      cellRender: { Accion },
                      hidingPriority: 0,
                    },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListarPorEliminarHomeSlides);
