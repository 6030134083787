import { useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import ErrorBoundary from "../components/exception/error";
import NoPermit from "./NoPermit";
import { permitRoute } from "../utils/tools";

function PrivateRoute({ component: C, isAuthenticated, ...rest }) {
  const [empresa] = useState(localStorage.getItem("empresa"));

  if (empresa == "HB") {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            permitRoute(rest.path) ? (
              <ErrorBoundary>
                <C {...props} />
              </ErrorBoundary>
            ) : (
              <NoPermit />
            )
          ) : (
            <Redirect to="/auth/login" />
          )
        }
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <ErrorBoundary>
              <C {...props} />
            </ErrorBoundary>
          ) : (
            <Redirect to="/auth/login" />
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
  };
};
export default connect(mapStateToProps, null)(PrivateRoute);
