import React from 'react'
import { FormularioFooterComponent } from '../../../components/configuracion/footer/formulario-footer'
import { EstadoContenido } from '../../../utils/constants'

const FooterEnRevisionPage = () => {
  return (
    <FormularioFooterComponent estado={EstadoContenido.EnRevision} />
  )
}

export default FooterEnRevisionPage
