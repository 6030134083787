import React from 'react'
import { FormularioFooterComponent } from '../../../components/configuracion/footer/formulario-footer'
import { EstadoContenido } from '../../../utils/constants'

const FooterPorAprobarPage = () => {
  return (
    <FormularioFooterComponent estado={EstadoContenido.EnAprobacion}/>
  )
}

export default FooterPorAprobarPage
