/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Fetch, catchError } from "../../../../utils/fetch";
import { AlertConfirm } from "../../../../components/alert";
import { connect, useSelector } from "react-redux";
import { loadingAction } from "../../../../redux/actions";
import { formatDate } from "../../../../utils/formatDate";
import DataGridForm from "../../../../components/common/DataGridForm";
import { Tooltip as ReactTooltip } from "react-tooltip";

function ListarHomeSlides({ isLoadingAction, isLoading }) {
  const [contenido, setContenido] = React.useState(null);
  const idEmpresa = useSelector((state) => state.user.data.empresa.id);

  function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== "";
  }

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch(
        `home-slides?_eliminado=false&idEmpresa=${idEmpresa}&_sort=orden:ASC`,
        "get"
      );
      setContenido(data);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  const deleteContenido = async (id) => {
    isLoadingAction(true);
    try {
      const params = {
        eliminado: true,
      };
      await Fetch("home-slides/" + id, "put", params);
      getContenido();
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function RenderAlert(title, id) {
    AlertConfirm(title, "question", (response) => {
      if (response.isConfirmed) {
        deleteContenido(id);
      }
    });
  }

  const ordenContenido = async (e) => {
    try {
      isLoadingAction(true);

      let request = [];
      e.forEach(async (item, indice) => {
        const params = {
          orden: indice,
        };
        request.push(Fetch("home-slides/" + item.id, "put", params));
      });

      Promise.all(request).then((response) => {
        isLoadingAction(false);
      });
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const handlerOnOff = async (prmId, value) => {
    isLoadingAction(true);
    try {
      // const params = {
      //   onOff: value,
      // };
      await Fetch("home-slides/seleccionar/" + prmId + "/" + value, "put", {});
      getContenido();
      isLoadingAction(false);
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const onReorder = async (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newData = [...contenido];
    const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newData.indexOf(e.itemData);

    newData.splice(fromIndex, 1);
    newData.splice(toIndex, 0, e.itemData);

    setContenido(newData);
    await ordenContenido(newData);
  };

  function Imagen(data) {
    return data?.media?.formats?.thumbnail?.url ? (
      <>
        <img
          src={data.media.formats.thumbnail.url}
          style={{
            width: "64px",
            height: "64px",
            borderRadius: "32px",
            objectFit: "cover",
          }}
          className=""
          alt="images"
        />
      </>
    ) : null;
  }

  function FActualizacion(data) {
    return formatDate(data?.updated_at);
  }

  function OnOff(data) {
    return (
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          checked={data.onOff}
          class="custom-control-input"
          onChange={(v) => handlerOnOff(data.id, v.target.checked)}
          id={"csPublicado" + data.id}
        />
        <label
          role="button"
          class="custom-control-label"
          for={"csPublicado" + data.id}
        ></label>
      </div>
    );
  }

  function Accion(data) {
    return (
      <>
        <a data-tooltip-id="my-tooltip" data-tooltip-content="Editar">
          <ReactTooltip id="my-tooltip" />
          <Link
            to={"/configuracion/home-slide/ver/" + data.id}
            className="btn btn-sm btn btn-success"
          >
            <i className="fa fa-pencil" />
          </Link>{" "}
        </a>
        <a data-tooltip-id="my-tooltip" data-tooltip-content="Ver">
          <ReactTooltip id="my-tooltip" />
          <a
            href={`${data?.media?.url}`}
            target="_blank"
            className="btn btn-sm btn btn-info"
          >
            <i className="fa fa-eye" />
          </a>{" "}
        </a>
        <a data-tooltip-id="my-tooltip" data-tooltip-content="Eliminar">
          <ReactTooltip style={{ zIndex: 2 }} id="my-tooltip" />
          <button
            style={{ zIndex: 1 }}
            type="button"
            className="btn btn-sm btn btn-danger"
            onClick={() => {
              RenderAlert("Está apunto de eliminar este contenido", data.id);
            }}
          >
            <i className="fa fa-trash" />
          </button>{" "}
        </a>
      </>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              Lista de banners
              <div className="float-right mt-10">
                <Link
                  to="/configuracion/home-slide/nuevo"
                  className="btn btn-primary btn-rounded box-shadow btn-icon"
                >
                  <i className="fa fa-plus" /> Nuevo Banner
                </Link>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <DataGridForm
                  dataSource={contenido}
                  keyExpr="id"
                  allowReordering={true}
                  onReorder={onReorder}
                  columnHidingEnabled={true}
                  columns={[
                    { caption: "Id", dataField: "id" },
                    {
                      caption: "Imagen",
                      alignment: "center",
                      render: "Imagen",
                      cellRender: { Imagen },
                    },
                    { caption: "Creado por", dataField: "creado_por.username" },
                    {
                      caption: "Actualizado por",
                      dataField: "actualizado_por.username",
                    },
                    {
                      caption: "Ult. Actualización",
                      render: "FActualizacion",
                      cellRender: { FActualizacion },
                    },
                    {
                      caption: "Inactivo/Activo",
                      alignment: "center",
                      render: "OnOff",
                      cellRender: { OnOff },
                    },
                    {
                      caption: "Acción",
                      alignment: "center",
                      render: "Accion",
                      cellRender: { Accion },
                      hidingPriority: 0,
                    },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListarHomeSlides);
