import * as Yup from 'yup';

export const validateModel = Yup.object().shape({
    Member: Yup.object().shape({
        lastName: Yup.string().required('Campo obligatorio'),
        firstName: Yup.string().required('Campo obligatorio'),
        email: Yup.string().required('Campo obligatorio'),
        sex: Yup.string().required('Campo obligatorio'),
        dniType: Yup.string().required('Campo obligatorio'),
        dni: Yup.string().required('Campo obligatorio'),
        cellCompany: Yup.string().required('Campo obligatorio'),
        mobile: Yup.string().required('Campo obligatorio'),
        // phone: Yup.string().required('Campo obligatorio'),
        // socialSecurity: Yup.string().required('Campo obligatorio'),
        // socialSecurityPlan: Yup.string().required('Campo obligatorio'),    
        // socialSecurityNumber: Yup.string().required('Campo obligatorio'),
        familyMembers: Yup.number().required('Campo obligatorio'),
        // ivaTax: Yup.string().required('Campo obligatorio'),
        accessLevel: Yup.string().required('Campo obligatorio'),
        sectorLevel: Yup.string().required('Campo obligatorio'),
        // idSap: Yup.string().typeError('idSap no puede estar vacio').required('Campo obligatorio'),
    })
});