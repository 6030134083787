import React from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import { Fetch, catchError, fetchLogs } from "../../../utils/fetch";
import { loginAction, permissionsAction } from "../../../redux/actions";
import { AlertInfo, AlertSuccess } from "../../../components/alert";

function LoginPage(props) {
  const history = useHistory();
  const { loginActionProps, permissionsActionProps } = props;
  const [user, setUser] = React.useState(null);
  const [pass, setPass] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState(true);

  const auth = async () => {
    try {
      const { data } = await axios({
        url: process.env.REACT_APP_BASE_URL + "/external-auth/auth",
        method: "POST",
      });
      return data;
    } catch (err) {}
  };

  const external = async (data, token) => {
    try {
      return await axios({
        url: `${process.env.REACT_APP_BASE_URL_GW_API}/customers/Api/v1/login`,
        data,
        method: "post",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
    } catch (err) {}
  };

  const onLoginSql = async () => {
    const { token } = await auth();
    const { data } = await external({ email: user, password: pass }, token);

    return {
      error: data?.error,
      tokenApp: token,
      tokenUser: data?.data?.Token,
      message:data.message
    };
  };

  const handleSubmit = async () => {
    localStorage.clear();
    const params = {
      identifier: user,
      password: pass,
    };
    try {
      setLoading(true);

      //LOGIN SQL
      const loginSql = await onLoginSql();      

      if (loginSql.error) {
        if (loginSql.message === "Usuario no validado") {
          fetchLogs({
            idLogType: 1,
            idType: 15,
            description:
            "Han intentado acceder al CMS del Portal Web con el correo " + user + " pero el email no existe",
          });
        }
        AlertInfo(loginSql.message);
        setLoading(false)
        return
      };

      //LOGIN STRAPI
      const { data } = await Fetch("auth/local", "post", params);

      localStorage.setItem("jwt_token", data.jwt);
      localStorage.setItem("tokenApp", loginSql.tokenApp);
      localStorage.setItem("tokenUser", loginSql.tokenUser);
      localStorage.setItem("userData", JSON.stringify(data.user));
      localStorage.setItem("empresa", JSON.stringify(data.user.empresa.nombre));

      loginActionProps(data.jwt, data.user);
      checkPermissions(data.user.role);

      setLoading(false);
    } catch (error) {     
      catchError(error);
      AlertInfo("Credenciales incorrectas, favor verificar");
      setLoading(false);
      console.error("mostrando error login", error);
    }
  };

  const checkPermissions = async (rol) => {
    try {
      setLoading(true);

      const { data } = await Fetch("users-permissions/roles/" + rol.id, "get");
      const permissions = data.role.permissions.application.controllers;

      permissionsActionProps(permissions);
      AlertSuccess("Login exitoso!");

      if (rol.type == "aprobador" || rol.type == "creador") {
        history.push("/dashboard");
      } else {
        history.push("/inicio");
      }
    } catch (error) {
      catchError(error);
      AlertInfo(
        "Se ha presentado un problema al intentar hacer login, verifique usuario y contraseña"
      );
      setLoading(false);
      console.error("mostrando error login", error);
    }
  };
  return (
    <React.Fragment>
      <div className="misc-wrapper">
        <div className="misc-content">
          <Container>
            <Row className="justify-content-center">
              <Col sm="12" md="5" lg="4" className="col-4">
                <div to="#javascript" className="misc-header text-center">
                  <Link to="/">
                    <Image
                      alt=""
                      src="/assets/img/logo-ps.png"
                      className="toggle-none hidden-xs"
                    />
                  </Link>
                </div>
                <div className="misc-box">
                  <Form>
                    <Form.Group>
                      <label htmlFor="exampleuser1">Usuario</label>
                      <div className="group-icon">
                        <input
                          id="exampleuser1"
                          type="text"
                          onChange={(e) => setUser(e.target.value)}
                          placeholder="Usuario"
                          className="form-control"
                        />
                        <span className="icon-user text-muted icon-input" />
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="exampleInputPassword1">Contraseña</label>
                      <div className="group-icon">
                        <input
                          id="exampleInputPassword1"
                          type={type ? "password" : "text"}
                          onChange={(e) => setPass(e.target.value)}
                          placeholder="Password"
                          className="form-control"
                        />
                        <span
                          className={
                            (type ? "icon-lock" : "icon-lock-open") +
                            " text-muted icon-input"
                          }
                          style={{ cursor: "pointer" }}
                          onClick={() => setType(!type)}
                        />
                      </div>
                    </Form.Group>
                    <Button
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={loading}
                      className="btn btn-block btn-primary btn-rounded box-shadow"
                    >
                      {loading ? "Cargando..." : "Iniciar"}
                    </Button>
                  </Form>
                </div>
                <div className="text-center misc-footer">
                  <p>Copyright © 2022 Plan de Salud Hospital Británico</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginActionProps: (data, user) => {
      dispatch(loginAction(data, user));
    },
    permissionsActionProps: (data) => {
      dispatch(permissionsAction(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);
